<template>
  <div id="modalNovaDespesa">
    <q-dialog v-model="OpenDialogNovaDespesa" persistent>
      <q-card
        :class="{
          'scroll': true,
          'nova-despesa-card': true,
          'open': opcoesAvancadas,
        }"
        ref="novaDespesaCard"
      >
        <q-card-section class="row items-center">
          <div class="text-h6">Nova Despesa</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModal()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <div class="row q-col-gutter-md">
            <div :class="opcoesAvancadas ? 'col-6' : 'col-12'">
              <div class="row">
                <div class="col" id="vencimento">
                  <q-input
                    bottom-slots
                    :error="errors.vencimento ? true : false"
                    v-model="movconta.vencimento"
                    label="Vencimento"
                    @click="$refs.qDateProxy.show()"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDateProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="movconta.vencimento"
                            @input="() => pulaProx('vencimento')"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.vencimento"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="row" v-if="!cadastroDespesa.isCadastro">
                <div class="col" id="autocomplete">
                  <autocomplete
                    title="Selecione o Fornecedor"
                    :filter="filterFn"
                    :carregando="carregando"
                    :options="options"
                    :formatDisplay="formatDisplay"
                    :create="createValue"
                    @resultAutocomplete="resultEmitRecebe"
                    :display="displayMontar"
                    tipoConsulta="cadastro"
                    :errors="errors.cadastro_id"
                    :cadastro="abreCadastro"
                    :newCadastro="cadastroReturn"
                    ref="autocomplete"
                    :pularProx="() => pulaProx('autocomplete')"
                  />
                </div>
              </div>
              <div class="row q-col-gutter-sm">
                <div class="col" id="descricao">
                  <q-input
                    bottom-slots
                    :error="errors.descricao ? true : false"
                    v-model="movconta.descricao"
                    label="Descrição"
                    ref="descricao"
                    @keydown.enter.prevent="pulaProx('descricao')"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.descricao"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="row q-col-gutter-sm">
                <div class="col" id="referencia">
                  <q-input
                    bottom-slots
                    :error="errors.referencia ? true : false"
                    v-model="movconta.referencia"
                    label="Referência"
                    ref="referenciaDespesa"
                    @keydown.enter.prevent="pulaProx('referencia')"
                  >
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.referencia"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
                <div
                  class="col"
                  id="valor"
                  @keydown.enter.prevent="pulaProx('valor')"
                >
                  <q-field
                    v-model="movconta.valor"
                    label="Valor"
                    bottom-slots
                    id="valor"
                    :error="errors.valor ? true : false"
                  >
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                        ref="valor"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span v-for="(error, i) in errors.valor" :key="error + i">{{
                        error
                      }}</span>
                    </template>
                  </q-field>
                </div>
              </div>
              <div class="row">
                <div class="col" id="planodeconta">
                  <q-select
                    v-model="valuePC"
                    use-input
                    :option-label="
                      value =>
                        value === null
                          ? 'Item Vazio'
                          : value.caminho != null
                          ? value.caminho
                          : value.titulo
                    "
                    :option-value="value => (value === null ? null : value)"
                    :options="planoContasFilterResult"
                    label="Plano de Conta"
                    clearable
                    ref="planodecontaDespesa"
                    @filter="planoContasFilter"
                    @input="() => pulaProx('planodeconta')"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
              <div class="row q-pt-md" v-if="$route.path != '/caixas/' + $route.params.id">
                <div class="col">
                  <q-checkbox
                    v-model="cobrar"
                    clickable
                    label="Incluir forma de pagamento"
                    color="green"
                    id="checkDespesa"
                  />
                </div>
              </div>
              <div class="row ">
                <div class="col" v-if="cobrar && $route.path != '/caixas/' + $route.params.id">
                  <q-select
                    clearable
                    v-model="movconta.gateway_id"
                    option-label="titulo"
                    option-value="id"
                    :options="findGatewaysDespesa.gateways"
                    emit-value
                    map-options
                    bottom-slots
                    :error="errors.gateway_id ? true : false"
                    label="Forma de Pagamento"
                    ref="formaPagamento"
                    @input="() => pulaProx('liquidado')"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.gateway_id"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-select>
                </div>
                <div class="col" v-if="!cobrar && $route.path != '/caixas/' + $route.params.id">
                  <q-select
                    v-model="movconta.conta_id"
                    :options="contas"
                    option-label="titulo"
                    option-value="id"
                    emit-value
                    map-options
                    bottom-slots
                    label="Conta"
                    clearable
                    ref="contaBancaria"
                    @input="() => pulaProx('liquidado')"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
              <div class="row" v-if="centroCustos.length > 0 && !ratear">
                <div class="col">
                  <q-select
                    v-model="movconta.centrocusto_id"
                    :option-label="
                      value => (value === null ? 'Item Vazio' : value.nome)
                    "
                    :option-value="value => (value === null ? null : value)"
                    :options="centroCustos"
                    label="Centro de Custo"
                    clearable
                    ref="centroCusto"
                  >
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-grey"
                          >Sem Resultados</q-item-section
                        >
                      </q-item>
                    </template>
                  </q-select>
                </div>
              </div>
              <div class="flex justify-between q-pt-md">
                <q-checkbox
                  v-model="movconta.liquidado"
                  clickable
                  label="Liquidado"
                  color="green"
                  id="liquidado"
                  v-if="liberaOpcaoLiquidado()"
                  @input="() => pulaProx('liquidado')"
                />
                <q-btn
                  flat
                  dense
                  no-caps
                  label="Opções Avançadas"
                  :icon-right="opcoesAvancadas ? 'keyboard_arrow_left' : 'keyboard_arrow_right'"
                  @click="opcoesAvancadas = !opcoesAvancadas"
                  v-if="!($route.name.includes('caixa'))"
                />
              </div>
              <div class="row">
                <div class="col" v-if="movconta.liquidado">
                  <q-input
                    bottom-slots
                    :error="errors.liquidacao ? true : false"
                    v-model="movconta.liquidacao"
                    label="Liquidado em"
                    @click="$refs.qDateProxy2.show()"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          ref="qDateProxy2"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="movconta.liquidacao"
                            @input="() => pulaProx('valorLiquidado')"
                            mask="DD/MM/YYYY"
                            :locale="langDate"
                            minimal
                            today-btn
                          />
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.liquidacao"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="row" @keydown.enter.prevent="pulaProx('salvar')">
                <div class="col" v-if="movconta.liquidado" id="valorPago">
                  <q-field
                    v-model="movconta.valor_liquidado"
                    label="Valor Pago"
                    bottom-slots
                    id="valorLiquidado"
                    :error="errors.valor_liquidado ? true : false"
                  >
                    <template
                      v-slot:control="{ id, floatingLabel, value, emitValue }"
                    >
                      <money
                        :id="id"
                        class="q-field__native text-right"
                        :value="value"
                        @input="emitValue"
                        v-bind="moneyFormat"
                        v-show="floatingLabel"
                        ref="valorLiquidado"
                      >
                      </money>
                    </template>
                    <template v-slot:error>
                      <span
                        v-for="(error, i) in errors.valor_liquidado"
                        :key="error + i"
                        >{{ error }}</span
                      >
                    </template>
                  </q-field>
                </div>
              </div>
            </div>
            <div :class="opcoesAvancadas ? 'col-6' : 'col-12'" v-show="opcoesAvancadas">
              <div class="row q-mb-sm">
                <div class="col-12 q-mb-md">
                  <q-checkbox
                    v-model="parcelar"
                    label="Parcelar"
                    color="green"
                    @input="() => pulaProx('parcelar')"
                  />
                </div>

                <div class="col-12 q-mb-md" v-show="parcelar">
                  <div class="row q-col-gutter-lg">
                    <div class="col-12">
                      <q-checkbox
                        v-model="dividirValorParcelas"
                        label="Dividir valor entre parcelas"
                        @input="() => pulaProx('parcelar')"
                      />
                      <p class="q-mb-none q-mt-md text-grey-7">
                        <span>{{ quantidadeParcelas }} parcelas de </span>
                        R$ <span>{{
                          (dividirValorParcelas && quantidadeParcelas > 0 ?
                            (movconta.valor / quantidadeParcelas)
                            : movconta.valor
                          ) | currency
                        }}
                        </span>
                      </p>
                    </div>
                    <div class="col-6">
                      <q-input
                        class="parcelas-input"
                        v-model="quantidadeParcelas"
                        label="Quantidade de parcelas"
                        mask="###"
                        ref="quantidadeParcelas"
                        @keydown.enter.prevent="pulaProx('quantidadeParcelas')"
                        bottom-slots
                        :error="Boolean(errors.quantidadeParcelas)"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errors.quantidadeParcelas"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </div>
                    <div class="col-6">
                      <q-input
                        class="parcelas-input"
                        v-model="diaParcelas"
                        label="Sempre no dia"
                        mask="##"
                        ref="diasParcelas"
                        @keydown.enter.prevent="pulaProx('salvar')"
                        bottom-slots
                        @input="diaParcelasEditado = true"
                        :error="Boolean(errors.diaParcelas)"
                      >
                        <template v-slot:error>
                          <span
                            v-for="(error, i) in errors.diaParcelas"
                            :key="error + i"
                            >{{ error }}</span
                          >
                        </template>
                      </q-input>
                    </div>
                  </div>
                </div>

                <div class="col-12 q-mb-md" v-if="centroCustos.length > 0">
                  <q-checkbox
                    v-model="ratear"
                    label="Rateio"
                    color="green"
                    @input="() => pulaProx('rateio')"
                  />
                </div>

                <div class="col-12 q-mb-md" v-show="ratear">
                  <div class="row">
                    <div class="col-12 q-mb-md">
                      <q-select
                        v-model="centroCustoRateio"
                        :option-label="
                          value => (value === null ? 'Item Vazio' : value.nome)
                        "
                        :option-value="value => (value === null ? null : value)"
                        :options="optionsCentroCustos"
                        label="Centro de Custo"
                        ref="centroCustoRateio"
                        :hide-bottom-space="centrocustoErro === null"
                        :error="centrocustoErro !== null"
                        @input="() => pulaProx('centroCustoRateio')"
                      >
                        <template v-slot:no-option>
                          <q-item>
                            <q-item-section class="text-grey"
                              >Sem Resultados</q-item-section
                            >
                          </q-item>
                        </template>
                        <template v-slot:error>
                          <span>{{ centrocustoErro }}</span
                          >
                        </template>
                      </q-select>
                    </div>
                    <div class="col-2 flex items-end">
                      <q-btn
                        color="white"
                        text-color="black"
                        label="%"
                        disable
                      />
                    </div>
                    <div class="col-8" @keydown.enter="addRateio()">
                      <q-field
                        v-model="porcentagemRateio"
                        label="Porcentagem"
                        id="porcentagemRateio"
                        :hide-bottom-space="centrocustoErro === null"
                        :error="porcentagemErro !== null"
                      >
                        <template
                          v-slot:control="{ id, floatingLabel, value, emitValue }"
                        >
                          <money
                            :id="id"
                            class="q-field__native text-right"
                            :value="value"
                            @input="emitValue"
                            v-bind="percentFormat"
                            v-show="floatingLabel"
                            ref="porcentagemRateio"
                          >
                          </money>
                        </template>
                        <template v-slot:error>
                          <span>{{ porcentagemErro }}</span
                          >
                        </template>
                      </q-field>
                    </div>
                    <div class="col-2 flex items-end justify-end">
                      <q-btn
                        icon="add"
                        color="primary"
                        flat
                        round
                        @click="addRateio"
                      />
                    </div>
                    <div class="col-12 q-mt-md">
                      <p
                        v-if="porcentagemRateioTotal > 100 && !Boolean(errors.rateios)"
                        class="text-negative caption"
                      >
                        A porcentagem total passa de 100%
                      </p>
                      <div v-if="Boolean(errors.rateios)">
                        <p
                          class="text-negative caption"
                          v-for="(error, index) in errors.rateios"
                          :key="index"
                        >
                          {{ error }}
                        </p>
                      </div>
                      <q-markup-table
                        flat
                        dense
                      >
                        <tbody>
                          <tr
                            v-for="(rateio, index) in rateios"
                            :key="index"
                          >
                            <td>{{ rateio.centrocusto_nome }}</td>
                            <td class="text-right">{{ rateio.porcentagem }} %</td>
                            <td style="width: 30px;">
                              <q-btn
                                flat
                                dense
                                color="red"
                                icon="delete"
                                @click="rateios.splice(index, 1)"
                              />
                            </td>
                          </tr>
                          <tr v-if="rateios.length > 0">
                            <td colspan="2"><strong>Total</strong></td>
                            <td class="text-right">{{ porcentagemRateioTotal }} %</td>
                          </tr>
                          <tr v-if="rateios.length === 0">
                            <td colspan="3" class="text-center">Nenhum rateio</td>
                          </tr>
                        </tbody>
                      </q-markup-table>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div
                    class="q-uploader"
                    style="width: 100%; box-shadow: none;overflow:hidden"
                    @dragleave="onDragLeave"
                    @mouseover="onHover"
                    @mouseleave="offHover"
                    @dragover="onDragOver"
                    @drop.stop.prevent="addFile"
                  >
                    <div class="q-uploader__header">
                      <div class="header-upload">
                        <a v-if="arrayFile && arrayFile.length > 0">
                          <div tabindex="-1" class="q-focus-helper"></div>
                          <q-btn
                            dense
                            flat
                            icon="clear_all"
                            @click="clearFiles"
                          />
                        </a>
                        <div :class="!this.$q.platform.is.mobile ?  'layout' :'layout-responsive'">
                          <div  v-if="!this.$q.platform.is.mobile">
                            <div class="q-uploader__title">Anexar imagens e documentos</div>
                          </div>

                          <div class="icones-layout">
                              <label for="file"  v-if="this.$q.platform.is.mobile">
                                <i
                                  class="material-icons"
                                  style="font-size: 20px; cursor: pointer; float: right"
                                >
                                  add_a_photo
                                </i>
                                <input
                                  accept="image/*"
                                  capture="camera"
                                  type="file"
                                  id="file"
                                  style="width: 0"
                                  multiple
                                  class="file"
                                  @change="addFile"
                                />
                              </label>

                              <label v-else>
                                <i
                                  @click="openModal"
                                  class="material-icons"
                                  style="font-size: 20px; cursor: pointer;"
                                >
                                  add_a_photo
                                </i>
                              </label>

                              <label>
                                <i v-if="!this.$q.platform.is.mobile"
                                  class="material-icons"
                                  style="font-size: 20px; cursor: pointer">
                                  cloud_upload
                                </i>
                                <input
                                  type="file"
                                  id="file"
                                  style="width: 0"
                                  multiple
                                  class="file"
                                  @change="addFile"
                                />
                              </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="q-uploader__list scroll" style="border: 1px solid #ccc;">
                      <div v-for="(file, i) in arrayFile" :key="file.name" style="margin-bottom: 8px">
                        <div class="q-uploader__file relative-position">
                          <div class="q-uploader__file-header row flex-center no-wrap">
                            <i
                              v-if="file.error"
                              aria-hidden="true"
                              class="material-icons q-icon text-negative q-uploader__file-status"
                            >
                              warning
                            </i>
                            <div class="q-uploader__file-header-content col">
                              <div class="q-uploader__title">{{file.fileName}}</div>
                                <div class="q-uploader__subtitle row items-center no-wrap">
                                  {{file.fileSize}}
                                </div>
                              </div>
                              <q-btn
                                round
                                dense
                                flat
                                icon="clear"
                                @click="apagaItem(i)"
                                :loading="file.uploading"
                              >
                                <template v-slot:loading>
                                  <q-spinner color="grey-10" size="1em" />
                                </template>
                              </q-btn>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div v-if="(arrayFile.length > 0)" class="q-mt-md">
                    <div class="text-h6">Anexos</div>

                    <q-markup-table
                      class="q-mt-sm"
                      style="border: 1px solid #ccc"
                      flat
                      dense
                    >
                      <tbody>
                        <tr
                          v-for="(file, index) in arrayFile"
                          :key="index"
                        >
                          <td>{{ file.fileName }}</td>
                        </tr>
                      </tbody>
                    </q-markup-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            flat
            label="Salvar & Novo"
            v-if="$route.path != '/caixas/' + $route.params.id"
            clickable
            @click="saveDespesaNovo"
            :loading="salvarNovo"
            ref="salvarNovo"
            class="bg-grey-6 text-white"
            style="margin-right: 10px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
          <q-btn
            flat
            label="Salvar"
            clickable
            @click="saveDespesa"
            :loading="loadNovoREC"
            id="salvar"
            class="bg-positive text-white"
            style="margin-right: 22px; margin-bottom: 10px"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios-auth";
import { Notify, scroll } from "quasar";
import { bus } from "@/main";
import { Money } from "v-money";
const moment = require("moment");
export default {
  name: "modal-nova-despesa",
  components: { Money },
  data() {
    return {
      cobrar: false,
      valuePC: null,
      errors: [],
      planoContasFilterResult: [],
      movconta: this.newMovconta(),
      salvarNovo: false,
      moneyFormat: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      },
      percentFormat: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false
      },
      opcoesAvancadas: false,
      parcelar: false,
      quantidadeParcelas: null,
      diaParcelas: null,
      ratear: false,
      centroCustoRateio: null,
      porcentagemRateio: 0,
      rateios: [],
      porcentagemErro: null,
      centrocustoErro: null,
      dividirValorParcelas: false,
      diaParcelasEditado: false,

      // autocomplete
      carregando: false,
      options: [],
      selectItem: null,
      autocompleteResult: null,
      saving: false,
      arrayFile: [],
      selectedFiles: []
    };
  },
  methods: {
    planoContasFilter(val, update) {
      update(() => {
        this.planoContasFilterResult = this.planoContas.filter(
          plano => plano.titulo.toLowerCase().indexOf(val.toLowerCase()) > -1
        );
      });
    },
    liberaOpcaoLiquidado() {
      let routesBloqueadas = ["resumo_tfl", "caixa_operador", "caixa"];

      for (let i = 0; i < routesBloqueadas.length; i++) {
        if (routesBloqueadas[i] === this.$route.name) {
          routesBloqueadas.splice(i, 1);
        }
      }
      return routesBloqueadas.length === 3 ? true : false;
    },
    pulaProx(value) {
      switch (value) {
        case "vencimento":
          this.$refs.qDateProxy.hide();
          this.$refs.autocomplete.$refs.autocompletedComponent.focus();
          break;
        case "autocompleteReturn":
          this.$refs.autocomplete.$refs.autocompletedComponent.focus();
          break;
        case "autocomplete":
          this.$refs.descricao.focus();
          break;
        case "liquidacao":
          this.$refs.qDateProxy2.hide();
          document.getElementById("valorLiquidadoDespesa").focus();
          break;
        case "descricao":
          this.$refs.referenciaDespesa.focus();
          break;
        case "referencia":
          this.$refs.valor.$el.focus();
          break;
        case "valor":
          this.$refs.planodecontaDespesa.focus();
          break;
        case "planodeconta":
          document.getElementById("checkDespesa").focus();
          break;
        case "debitoemconta":
          if (this.cobrar) {
            setTimeout(() => {
              this.$refs.formaPagamento.focus();
            }, 200);
          } else {
            setTimeout(() => {
              this.$refs.contaBancaria.focus();
            }, 200);
          }
          break;
        case "valorLiquidado":
          this.$refs.valorLiquidado.$el.focus();
          break;
        case "liquidado":
          document.getElementById("liquidado").focus();

          if (this.movconta.liquidado) {
            setTimeout(() => {
              this.$refs.qDateProxy2.show();
            }, 500);
          }
          break;
        case "salvar":
          this.$refs.salvarNovo.click();
          break;
        case "centroCusto":
          if (this.centroCustos.length > 0) {
            setTimeout(() => {
              this.$refs.centroCusto.focus();
            }, 500);
          }
          break;
        case "parcelar":
          setTimeout(() => {
            this.$refs.quantidadeParcelas.focus();
          }, 10);
          break;
        case "quantidadeParcelas":
          this.$refs.diasParcelas.focus();
          break;
        case "rateio":
          setTimeout(() => {
            this.$refs.centroCustoRateio.focus();
            this.$refs.centroCustoRateio.showPopup();
          }, 10);
          break;
        case "centroCustoRateio":
          this.$refs.porcentagemRateio.$el.focus();
          break;
      }
    },
    findGateway() {
      this.$store.dispatch("findGatewaysDespesa");
    },
    newMovconta() {
      this.cobrar = false;
      return {
        id: null,
        tipo: 2,
        vencimento: moment().format("DD/MM/YYYY"),
        liquidacao: moment().format("DD/MM/YYYY"),
        conta_id: null,
        descricao: null,
        valor: 0.0,
        valor_liquidado: 0.0,
        valor_acrescimo_desconto: 0.0,
        cadastro_id: null,
        cadastro_nome: null,
        liquidado: false,
        conciliado: false,
        fatura_id: null,
        shortUrl: null,
        status: "Aberto"
      };
    },
    resetModal() {
      this.movconta = this.newMovconta();
      this.errors = [];
      this.opcoesAvancadas = false;
      this.parcelar = false;
      this.ratear = false;
      this.quantidadeParcelas = null;
      this.diaParcelas = null;
      this.cobrar = false;
      this.centroCustoRateio = null;
      this.porcentagemRateio = 0;
      this.rateios = [];
      this.valuePC = null;
      this.dividirValorParcelas = false;
      this.diaParcelasEditado = false;
      this.autocompleteResult = null;
      this.$root.$emit('limpar');
      this.selectedFiles = [];
      this.arrayFiles = [];
    },
    closeModal() {
      this.$store.commit("OpenDialogNovaDespesa", false);
      this.$store.commit("cadastroDespesa", {
        isCadastro: false,
        cadastro: {}
      });
      this.resetModal();
    },
    saveDespesaNovo() {
      this.salvarNovo = true;
      this.saveDespesa();
    },
    async saveDespesa() {
      if (!this.salvarNovo) this.$store.commit("loadNovoREC", true);

      let newMov = { ...this.movconta };

      if (!newMov.liquidado) {
        newMov.liquidacao = null;
        newMov.valor_liquidado = 0;
      } else {
        newMov.status = "Liquidado";
      }

      if (this.valuePC !== null) {
        newMov.planodeconta_id = this.valuePC.id;
      }
      if (this.centrocusto_id !== null) {
        newMov.centrocusto_id = this.movconta.centrocusto_id
          ? this.movconta.centrocusto_id.id
          : null;
      }

      if (this.cadastroDespesa.isCadastro) {
        newMov.cadastro_id = this.cadastroDespesa.cadastro.cadastro_id;
        newMov.cadastro_nome = this.cadastroDespesa.cadastro.cadastro_nome;
      }

      if (this.autocompleteResult && this.autocompleteResult.id) {
        newMov.cadastro_id = this.autocompleteResult.id;
        newMov.cadastro_nome = this.autocompleteResult.name;
      }

      if (
        this.autocompleteResult &&
        this.autocompleteResult.id == null &&
        this.autocompleteResult.name != null
      ) {
        let cadastro = {
          nome: this.autocompleteResult.name,
          fornecedor: true,
        };
        const res = await axios.post("/cadastros/add", cadastro);

        if (res?.data?.success) {
          this.updateAutocompleteCadastro(res.data.data.cadastro);

          newMov.cadastro_id = res.data.data.cadastro.id;
          newMov.cadastro_nome = res.data.data.cadastro.nome;
        } else {
          this.errors = res?.data?.errors;
          Notify.create({
            message: res?.data?.message || "Não foi possível contactar, ou você não possui permissão!",
            color: "orange",
            position: "top-right"
          });

          this.$store.commit("loadNovoREC", false);
        }
      }

      return this.despesa(newMov);
    },
    despesa(newMov) {
      if (this.saving) {
        return;
      }
      this.saving = true;

      let url = "/financeiro/add/despesas";

      if (this.parcelar) {
        url = "/financeiro/add-multiple/despesa";
        newMov.action = "recorrencia";
        newMov.quantidadeParcelas = this.quantidadeParcelas;
        newMov.diaParcelas = this.diaParcelas;
        newMov.dividirValorParcelas = this.dividirValorParcelas;
      }

      newMov.ratear = this.ratear;
      newMov.rateios = this.rateios;

      axios
        .post(url, newMov)
        .then(res => {
          if (this.parcelar) {
            this.saving = false;
            this.$store.commit("loadNovoREC", false);

            if (!res.data.success) {
              this.salvarNovo = false;
              this.errors = res.data.errors;
              Notify.create({
                message: "Não foi possível salvar, ou não possui permissão!",
                color: "red",
                position: "top-right"
              });
              return;
            }

            Notify.create({
              message: "Lançamentos adicionados!",
              color: "green",
              position: "top-right"
            });

            if (this.$route.path === "/despesas") {
              this.$store.dispatch("resetPagingDespesas");
              this.$store.dispatch("listarDespesas");
            }

            if (!this.salvarNovo) {
              this.closeModal();
            } else {
              this.resetModal();

              setTimeout(() => {
                this.pulaProx("autocompleteReturn");
              }, 100);

              scroll.setScrollPosition(this.$refs.novaDespesaCard.$el, 0);
              this.salvarNovo = false;
            }

            return;
          }

          if (res.data.status) {
            Notify.create({
              message: "Lançamento " + res.data.movconta.id + " adicionado!",
              color: "green",
              position: "top-right"
            });

            if (this.selectedFiles.length > 0) {
              this.selectedFiles.forEach((file, index) => {
                this.uploadImages(file, this.arrayFile[index], res.data.movconta.id);
              });
            }

            this.valuePC = null;

            if (!this.salvarNovo) {
              this.closeModal();
            } else {
              this.resetModal();

              setTimeout(() => {
                this.pulaProx("autocompleteReturn");
              }, 100);

              scroll.setScrollPosition(this.$refs.novaDespesaCard.$el, 0);
              this.salvarNovo = false;
            }

            this.$store.commit("movcontaReturn", {
              cadastro: res.data.cadastro,
              movconta: res.data.movconta
            });

            if (this.acaoCaixa === 6) {
              let cadastro =
                this.movcontaReturn.cadastro != null
                  ? this.movcontaReturn.cadastro.nome
                  : "Sem cadastro";

              let descricaoVenda =
                "Pago: " +
                this.movcontaReturn.movconta.id +
                ", " +
                moment
                  .utc(this.movcontaReturn.movconta.vencimento)
                  .format("DD/MM/YYYY") +
                " - " +
                this.movcontaReturn.movconta.descricao +
                " - " +
                cadastro;

              this.$store.commit("venda", {
                descricao: descricaoVenda,
                valor: this.movcontaReturn.movcontavalor
              });
            }
            if (this.$route.path === "/despesas") {
              this.$store.dispatch("resetPagingDespesas");
              this.$store.dispatch("listarDespesas");
            }
            this.$forceUpdate();
          } else {
            this.errors = res.data.errors;
            this.$store.commit("loadNovoREC", false);
          }
          this.$store.commit("loadNovoREC", false);
          this.$store.commit("callDespesa", false);
          this.salvarNovo = false;

          if (this.$route.path === "/cadastros/" + this.$route.params.id) {
            bus.$emit("listarDespesas");
          }

          setTimeout(() => {
            this.saving = false;
          }, 500);
        })
        .catch(() => {
          Notify.create({
            message: "Não foi possível salvar, ou não possui permissão!",
            color: "red",
            position: "top-right"
          });
          this.$store.commit("loadNovoREC", false);
          this.salvarNovo = false;

          setTimeout(() => {
            this.saving = false;
          }, 500);
        });
    },
    // Funções do autocomplete
    filterFn(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros/busca?fornecedor=1&funcionario=1&term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    createValue(val, done) {
      let newCadastro = {
        id: null,
        name: val
      };
      done(newCadastro, "add-unique");
      this.pulaProx("autocomplete");
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      this.autocompleteResult = null;
      this.$store.commit("cadastroReturn", null);
      this.autocompleteResult = result;

      this.pulaProx("autocomplete");
    },
    displayMontar(value) {
      let format = value.vencimento + " - " + value.descricao;
      return !value ? value : value.name;
    },
    abreCadastro(value) {
      const autocompleteId = this.autocompleteResult ? this.autocompleteResult.id : null;

      if (value.id || autocompleteId) {
        this.$store.dispatch("cadastro", {
          id: value.id || autocompleteId,
        });
        return;
      }

      this.$store.dispatch("cadastro", {
        nome: value.name,
        noSearch: true,
        noCliente: false,
        noFuncionario: false,
        noFornecedor: true,
      });
    },
    addRateio() {
      this.porcentagemErro = null;
      this.centrocustoErro = null;

      if (!this.centroCustoRateio) {
        this.centrocustoErro = "Centro de custo é obrigatório";
        return;
      }

      if (!this.porcentagemRateio) {
        this.porcentagemErro = "Porcentagem é obrigatória";
        return;
      }

      if (this.porcentagem < 0 || this.porcentagem > 100) {
        this.porcentagemErro = "Valor inválido";
        return;
      }

      const rateioIndex = this.rateios.findIndex(
        rateio => rateio.centrocusto_id === this.centroCustoRateio.id
      );

      const rateio = {
        centrocusto_id: this.centroCustoRateio.id,
        centrocusto_nome: this.centroCustoRateio.nome,
        porcentagem: Math.abs(Number(this.porcentagemRateio)),
      };

      if (rateioIndex === -1) {
        this.rateios.push(rateio);
      } else {
        this.rateios[rateioIndex] = rateio;
      }

      this.centroCustoRateio = null;
      this.porcentagemRateio = 0;

      this.pulaProx("rateio");
    },
    updateAutocompleteCadastro(cadastro) {
      this.autocompleteResult = cadastro;

      if (!Array.isArray(this.options)) {
        this.options = [];
      }

      const cadastroIndex = this.options.findIndex(option => option ? option.id === cadastro.id : false);
      if (cadastroIndex) {
        this.options.splice(cadastroIndex, 1);
      }

      bus.$emit("updateAutocompleteField", {
        id: cadastro.id,
        name: cadastro.nome,
        label: cadastro.nome,
        canEdit: true,
      });
    },
    onDragLeave() {
      event.preventDefault();
    },
    onHover() {
      event.preventDefault();
    },
    offHover() {
      event.preventDefault();
    },
    onDragOver() {
      event.preventDefault();
    },
    addFile(event) {
      const files = this.getFiles(event);

      for (let i = 0, f; (f = files[i]); i++) {
        this.selectedFiles.push(f);

        this.arrayFile.push({
          fileName: f.name,
          fileSize: this.bytesToSize(f.size),
          success: false,
          error: false,
          message: "0%",
          uploading: false,
        });
      }
    },
    getFiles(event) {
      return event.dataTransfer ? event.dataTransfer.files : event.target.files;
    },
    uploadImages(arq, arr, movconta_id) {
      arr.uploading = true;
      const fd = new FormData();
      fd.append("file", arq, arq.name);
      fd.append("movconta_id", movconta_id);

      axios
        .post("/arquivos/upload", fd)
        .then(res => {
          if (!res.data.success) {
            arr.error = true;
            arr.message = res.data.message;

            Notify.create({
              message: `${arr.message} ${arr.fileName}`,
              color: "orange",
              position: "top-right"
            });

            return;
          }

          arr.message = res.data.message;
          arr.success = res.data.success;
          arr.uploading = false;
          arr.delivered = true;
        });
    },
    bytesToSize(bytes) {
      if (bytes == 0) return "0 Byte";

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    openModal() {
      this.$store.dispatch("getTokenQrcode");
      this.$store.commit("OpenDialogUploadResumoCaixas", true);
      this.$refs.uploadResumoTfl.dizsize();
    },
    apagaItem(i) {
      if (this.arrayFile[i].uploading) return;

      this.arrayFile.splice(i, 1);
    },
    clearFiles() {
      this.arrayFile = [];
    },
  },
  computed: {
    ...mapGetters([
      "acaoCaixa",
      "contas",
      "planoContas",
      "loadNovoREC",
      "findGatewaysDespesa",
      "movcontaReturn",
      "cadastroReturn",
      "centroCustos",
      "cadastroDespesa"
    ]),
    OpenDialogNovaDespesa: {
      get() {
        return this.$store.getters.OpenDialogNovaDespesa;
      },
      set() { }
    },
    porcentagemRateioTotal() {
      return this.rateios.reduce((total, rateio) => total + Math.abs(Number(rateio.porcentagem)), 0);
    },
    optionsCentroCustos() {
      return this.centroCustos.filter(centroCusto => {
        return !(this.rateios.find(rateio => rateio.centrocusto_id == centroCusto.id));
      });
    },
  },
  mounted() {
    bus.$on("novoCadastroAdicionado", () => {
      this.updateAutocompleteCadastro(this.cadastroReturn);
    });
  },
  watch: {
    // Atualiza o dia da parcela com o dia do vencimento
    "movconta.vencimento": {
      immediate: true,
      handler(newVencimento, oldVencimento) {
        if (!this.diaParcelasEditado) {
          const dataArray = String(newVencimento).split('/');
          const dia = dataArray[0];

          if (dia) {
            this.diaParcelas = dia;
          }
        }

        const newDtvencimento = moment(newVencimento, "DD/MM/YYYY");
        const oldDtvencimento = moment(oldVencimento, "DD/MM/YYYY");

        if (!this.movconta.referencia || oldDtvencimento.format("MM/YYYY") === this.movconta.referencia) {
          this.$set(this.movconta, "referencia", newDtvencimento.format("MM/YYYY"));
        }
      },
    },
    cobrar(value) {
      if (value) {
        this.$store.dispatch('findGatewaysDespesa');
      } else {
        this.movconta.gateway_id = null;
      }
    }
  },
};
</script>

<style scoped>
.nova-despesa-card {
  max-width: 400px;
  width: 90%;
}

.nova-despesa-card.open {
  max-width: 800px;
}

.layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
}

.layout-responsive {
  text-align: center;
  padding: 10px 10px;
}

.layout-responsive .icones-layout label {
  padding: 3rem;
}

.icones-layout label {
  margin-right: 5px;
}
</style>
