import { render, staticRenderFns } from "./modal-nova-receita.vue?vue&type=template&id=682f9aae&scoped=true&"
import script from "./modal-nova-receita.vue?vue&type=script&lang=js&"
export * from "./modal-nova-receita.vue?vue&type=script&lang=js&"
import style0 from "./modal-nova-receita.vue?vue&type=style&index=0&id=682f9aae&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "682f9aae",
  null
  
)

export default component.exports