<template>
  <q-page id="extrato-cliente">
    <sem-permissao v-if="!isPermissaoExtratoCliente"></sem-permissao>
    <div class="q-pa-md" v-else>
      <div class="q-col-gutter-md row">
        <div class="col-12 col-md-4">
          <q-card white bordered class="q-mb-md">
            <q-card-section>
              <div
                style="font-size: 20px;letter-spacing: 0.005em;font-weight: 400;"
              ></div>
              <div>
                <q-select
                  use-input
                  clearable
                  autocomplete="off"
                  input-debounce="500"
                  label="Nome Cliente:"
                  hint=""
                  :options="options"
                  :loading="carregando"
                  :option-value="value => (value === null ? null : value)"
                  :option-label="value => (value === null ? 'Item Vazio' : value.nome)"
                  @input="resultEmitRecebe"
                  @filter="filterAutocomplete"
                  v-model="autocompleteResult"
                >
                  <template v-slot:option="scope">
                    <q-list>
                      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                        <q-item-section>
                          <q-item-label
                            v-html="formatDisplay(scope.opt)"
                          ></q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </template>
                  <template v-slot:hint>Selecione o cadastro para exibir o extrato.</template>
                </q-select>
              </div>
            </q-card-section>
          </q-card>

          <div>
            <q-table
              row-key="id"
              selection="multiple"
              :data="abertas"
              :pagination="pagination"
              :columns="abertasColumns"
              :selected.sync="abertasSelecionadas"
              :loading="carregandoExtrato"
            >
              <template v-slot:top>
                <div class="q-table__title">Débitos em Aberto do Cliente</div>
                <q-icon name="help_outline" size="20px" class="q-ml-sm text-grey-7 ">
                  <q-tooltip>
                    São outras contas em aberto que não utilizaram o saldo do cliente.
                  </q-tooltip>
                </q-icon>

                <router-link
                  target="_blank"
                  class="text-grey-7 q-ml-md"
                  :to="`/cadastros/${cadastro_id}`"
                  v-show="cadastro_id"
                >
                  <q-icon name="fa fa-external-link" size="18px" />
                  <q-tooltip>Visualizar cadastro</q-tooltip>
                </router-link>

                <q-space/>

                <q-btn
                  size="12px"
                  color="primary"
                  icon="assured_workload"
                  class="q-mr-md"
                  @click="openModalLimite()"
                >
                  <q-tooltip>Alterar Limite</q-tooltip>
                </q-btn>

                <q-btn
                  size="12px"
                  color="green-7"
                  icon="attach_money"
                  :loading="loadingLiquidarMassa"
                  :disable="abertasSelecionadas.length === 0"
                  @click="openModalLiquidarMassa()"
                >
                  <q-tooltip>Liquidar em massa</q-tooltip>
                </q-btn>
              </template>

              <template v-slot:bottom>
                <div class="full-width text-weight-bold" style="font-size: 1.1em;">

                  <div class="flex justify-between">
                    <span>Total</span>
                    <span>R$ {{ abertasTotal | currency }}</span>
                  </div>

                  <div class="flex justify-between">
                    <span>Total Selecionado</span>
                    <span>R$ {{ abertasSelecionadasTotal | currency }}</span>
                  </div>
                </div>
              </template>

              <template v-slot:body="props">
                <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                  <q-td auto-width>
                    <q-checkbox v-model="props.selected" color="secondary" />
                  </q-td>
                  <q-td key="vencimento" class="text-right">
                    {{ props.row.vencimento | formatDate }}
                  </q-td>
                  <q-td key="descricao" class="text-left">
                    <span v-if="props.row.descricao">
                      {{ returnDescricao(props.row.descricao) }}</span
                    >
                    <q-tooltip
                      v-if="
                        props.row.descricao && props.row.descricao.length > 40
                      "
                    >
                      {{ props.row.descricao }}
                    </q-tooltip>
                    <br />
                    Criado em: {{ props.row.created | formatDate }}
                  </q-td>
                  <q-td key="valor" class="text-right">
                    <span v-if="props.row.valor != 0">
                      R$ {{ props.row.valor | currency }}
                    </span>
                  </q-td>
                  <q-td v-if="$q.platform.is.desktop" class="tdfora">
                    <div class="divfora">
                      <div
                        class="divdentro q-gutter-sm"
                        style="margin-right: 10px"
                      >
                        <q-btn
                          size="12px"
                          color="green-7"
                          icon="attach_money"
                          @click="liquidar(props.row)"
                        />

                        <q-btn
                          size="12px"
                          color="negative"
                          icon="delete_forever"
                          @click="excluir(props.row)"
                        />

                      </div>
                    </div>
                  </q-td>
                </q-tr>
                <q-tr v-show="props.expand" :props="props">
                  <q-td colspan="100%">
                    <div class="q-py-sm q-gutter-sm">
                      <q-btn
                        size="12px"
                        color="green-7"
                        icon="attach_money"
                        @click="liquidar(props.row)"
                      />
                      <q-btn
                        size="12px"
                        color="negative"
                        icon="delete_forever"
                        @click="excluir(props.row)"
                      />
                    </div>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <q-table
            title="Extrato"
            :data="liquidadas"
            :columns="liquidadasColumns"
            row-key="id"
            :loading="carregandoExtrato"
            :bottom="false"
            :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'"
            :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'"
          >
            <template v-slot:bottom>
              <div></div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props" @click="props.expand = !props.expand && $q.platform.is.mobile">
                <q-td key="tipo" class="text-center">
                  <q-icon
                    :name="returnIcons(props.row.tipo)"
                    size="24px"
                    :class="returnColors(props.row.tipo)"
                  ></q-icon>
                </q-td>
                <q-td key="liquidacao" class="text-center">{{
                  props.row.liquidacao | formatDate
                }}</q-td>
                <q-td
                  key="descricao"
                  :title="props.row.favorecido"
                  class="text-left"
                >
                  <span v-if="props.row.favorecido && !cadastro_id">
                    <router-link
                      target="_blank"
                      :to="`/cadastros/${props.row.favorecido_id}`"
                    >
                      {{ returnDescricao(props.row.favorecido) }}
                    </router-link>
                    <br/>
                  </span>
                  <span v-if="props.row.descricao">
                    {{ returnDescricao(props.row.descricao) }}
                  </span>
                  <q-tooltip
                    v-if="
                      props.row.descricao && props.row.descricao.length > 40
                    "
                  >
                    {{ props.row.descricao }}
                  </q-tooltip>
                  <q-tooltip
                    v-if="
                      props.row.favorecido && props.row.favorecido.length > 40
                    "
                  >
                    {{ props.row.favorecido }}
                  </q-tooltip>
                  <br>
                  <span v-if="props.row.movcaixa_id">
                    {{ props.row.caixa_nome }} - Sessão {{ props.row.sessao }} {{ props.row.func_resp_nome }}
                  </span>
                </q-td>
                <q-td key="movcaixa_id" class="text-left">
                  <div v-if="props.row.movcaixa_id">
                    <div v-html="props.row.produto"> </div>
                    <div></div>
                  </div>
                </q-td>
                <q-td key="entrada" class="text-right">
                  <span v-if="props.row.entrada != 0">
                    R$ {{ props.row.entrada | currency }}
                  </span>
                </q-td>
                <q-td key="saida" class="text-right">
                  <span v-if="props.row.saida != 0">
                    R$ {{ props.row.saida | currency }}
                  </span>
                </q-td>
                <q-td
                  key="saldo"
                  class="text-right"
                  v-bind:class="{ 'text-red': props.row.saldo < 0 }"
                  >R$ {{ props.row.saldo | currency }}
                </q-td>
                <q-td v-if="$q.platform.is.desktop" class="tdfora">
                  <div class="divfora">
                    <div
                      class="divdentro q-gutter-sm"
                      style="margin-right: 10px"
                    >
                      <q-btn
                        size="12px"
                        icon="info"
                        color="primary"
                        @click="detalharMovcontasCliente(props.row)"
                        v-if="props.row.tipo != 6"
                      />

                      <q-btn
                        size="12px"
                        icon="reply"
                        color="secondary"
                        @click="estornarMovcontasCliente(props.row)"
                        v-if="props.row.tipo != 6"
                      />

                    </div>
                  </div>
                </q-td>
              </q-tr>
              <q-tr v-show="props.expand" :props="props">
                <q-td colspan="100%">
                  <div class="q-py-sm q-gutter-sm">
                    <q-btn
                      size="12px"
                      icon="info"
                      color="primary"
                      @click="detalharMovcontasCliente(props.row)"
                      v-if="props.row.tipo != 6"
                    />
                    <q-btn
                      size="12px"
                      icon="reply"
                      color="secondary"
                      @click="estornarMovcontasCliente(props.row)"
                      v-if="props.row.tipo != 6"
                    />
                  </div>
                </q-td>
              </q-tr>
            </template>

            <template v-slot:top>
              <q-btn
                flat
                round
                dense
                icon="add"
                color="primary"
                class="q-mr-md"
                @click="openModalAddMovcontasCliente()"
              />
              <div class="q-table__title">
                Extrato Cliente - Saldo Atual
                <span :class= 'saldoAtual > 0 ? "text-green" : "text-red" ' >
                 R$ {{ saldoAtual | currency }}
                </span>
              </div>
              <q-icon name="help_outline" size="20px" class=" text-grey-7 q-ml-sm">
                <q-tooltip>
                  Considera os lançamentos liquidados utilizando o saldo do cliente
                </q-tooltip>
              </q-icon>

              <q-space />

              <q-btn-group rounded flat no-wrap no-caps>
                <q-btn
                  class="white text-blue-7"
                  clickable
                  @click="changeFilterPeriodo('prev')"
                  rounded
                  icon="chevron_left"
                />
                <q-btn
                  class="white text-blue-7"
                  rounded
                  :label="dtini"
                >
                  <template v-slot:default>
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        v-model="dtini"
                        @input="selecionarData('qDateProxy')"
                      />
                    </q-popup-proxy>
                  </template>
                </q-btn>

                <q-btn
                  class="white text-blue-7"
                  rounded
                  :label="dtfim"
                >
                  <template v-slot:default>
                    <q-popup-proxy
                      ref="qDateProxy2"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        minimal
                        today-btn
                        v-model="dtfim"
                        @input="selecionarData('qDateProxy2')"
                      />
                    </q-popup-proxy>
                  </template>
                </q-btn>

                <q-btn
                  class="white text-blue-7"
                  clickable
                  @click="changeFilterPeriodo('next')"
                  rounded
                  icon="chevron_right"
                />
              </q-btn-group>

              <q-btn
                v-if="hasVideo"
                flat
                dense
                color="primary"
                icon="ondemand_video"
                style="margin-right: 10px"
                @click="modalIntro"
                >
                <template v-slot:default>
                  <q-tooltip>Introdução Extrato Bancário</q-tooltip>
                </template>
              </q-btn>

              <q-btn
                style="margin-right: 10px"
                flat
                dense
                icon="print"
                class="text-blue-7"
                clickable
                @click="imprimir()"
                :loading="loadingImprimir"
              >
                <template v-slot:loading>
                  <q-spinner color="grey-10" size="1em" />
                </template>
                <template v-slot:default>
                  <q-tooltip>Imprimir Relatório</q-tooltip>
                </template>
              </q-btn>

              <q-btn
                v-if="$q.screen.gt.xs"
                flat
                dense
                rounded
                no-wrap
                v-ripple
                class="q-ml-sm q-px-md bg-white text-black"
                no-caps
                icon="more_vert"
                style="margin-right: 10px"
              >
                <q-menu anchor="top right" self="top right">
                  <q-list class="text-grey-8" style="min-width: 100px">
                    <q-item aria-hidden="true">
                      <q-item-section
                        class="text-uppercase text-grey-7"
                        style="font-size: 0.7rem"
                        >Mais Opções</q-item-section
                      >
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      aria-hidden="true"
                      @click="loadExtrato()"
                    >
                      <q-item-section>Atualizar</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>

            <template v-slot:top-row>
              <q-tr v-show="hasReachedLimit">
                <q-td colspan="100%" style="padding-left: 28px;">
                  <q-icon name="warning" class="text-red q-mr-sm" />
                  <span>Limite de registros atingido. Para visualizar todos lançamentos clique na impressora.</span>
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </div>
    </div>

    <q-dialog v-model="openDialogAddMovcontasCliente" persistent>
      <q-card class="scroll" style="width: 100%; max-width: 400px;">
        <q-card-section class="row items-center">
          <div class="text-h6">Adicionar/Subtrair Saldo do Cliente</div>
          <q-space />
          <q-btn
            icon="close"
            clickable
            flat
            round
            dense
            v-close-popup
            @click="closeModalAddMovcontasCliente()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section class="q-pa-md">
          <div class="row q-col-gutter-x-md">

            <div class="col-12">
              <q-select
                clearable
                use-input
                bottom-slots
                label="Cliente"
                autocomplete="off"
                input-debounce="500"
                :options="options"
                :loading="carregandoForm"
                :error="Boolean(movcontasClienteErrors.cadastro_id)"
                :option-value="value => (value === null ? null : value)"
                :option-label="value => value === null ? 'Item Vazio' : value.name"
                @filter="filterAutocompleteForm"
                @input="resultEmitRecebeMovcontasCliente"
                v-model="autocompleteResultForm"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in movcontasClienteErrors.cadastro_id"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
                <template v-slot:option="scope">
                  <q-list>
                    <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                      <q-item-section>
                        <q-item-label
                          v-html="formatDisplay(scope.opt)"
                        ></q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </template>
              </q-select>
            </div>

            <div class="col-12">
              <q-input
                bottom-slots
                label="Descrição"
                :error="Boolean(movcontasClienteErrors.descricao)"
                v-model="movcontasCliente.descricao"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in movcontasClienteErrors.descricao"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-input
                bottom-slots
                label="Liquidação"
                :error="Boolean(movcontasClienteErrors.liquidacao)"
                @click="$refs.qDateLiquidacao.show()"
                v-model="movcontasCliente.liquidacao"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateLiquidacao"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        minimal
                        today-btn
                        mask="DD/MM/YYYY"
                        :locale="langDate"
                        @input="() => $refs.qDateLiquidacao.hide()"
                        v-model="movcontasCliente.liquidacao"
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in movcontasClienteErrors.liquidacao"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-input>
            </div>

            <div class="col-6">
              <q-field
                bottom-slots
                label="Valor"
                v-model="movcontasCliente.valor"
                :error="movcontasClienteErrors.valor ? true : false"
              >
                <template
                  v-slot:control="{ id, floatingLabel, value, emitValue }"
                >
                  <money
                    class="q-field__native text-right"
                    :id="id"
                    :value="value"
                    @input="emitValue"
                    v-show="floatingLabel"
                    v-bind="moneyFormat"
                  >
                  </money>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in movcontasClienteErrors.valor"
                    :key="error + i"
                    >{{ error }}</span
                  >
                </template>
              </q-field>
            </div>

            <div class="col-6">
              <q-select
                emit-value
                map-options
                bottom-slots
                label="Adicionar ou Subtrair"
                :options="tiposMovcontas"
                v-model="movcontasCliente.tipo"
                :error="Boolean(movcontasClienteErrors.tipo)"
              >
                <template v-slot:error>
                  <span
                    v-for="(error, i) in movcontasClienteErrors.tipo"
                    :key="error + i"
                  >
                    {{ error }}
                  </span>
                </template>
              </q-select>
            </div>

            <div class="col-12">
              <q-select
                emit-value
                map-options
                bottom-slots
                label="Conta"
                option-value="id"
                option-label="titulo"
                :options="contas"
                v-model="movcontasCliente.conta_id"
                :error="Boolean(movcontasClienteErrors.conta_id)"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Sem Resultados
                    </q-item-section>
                  </q-item>
                </template>
                <template v-slot:error>
                  <span
                    v-for="(error, i) in movcontasClienteErrors.conta_id"
                    :key="error + i"
                  >
                    {{ error }}
                  </span>
                </template>
              </q-select>
            </div>

          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingSalvarMovcontasCliente"
            @click="addMovcontasCliente()"
          />
        </q-card-actions>

      </q-card>
    </q-dialog>

    <q-dialog v-model="openDialogLiquidarMassa" persistent>
      <q-card style="width: 400px !important">
        <q-card-section class="row items-center">
          <div class="text-h6">Liquidar Receita em Massa</div>
          <q-space />
          <q-btn
            flat
            round
            dense
            v-close-popup
            icon="close"
            @click="closeModalLiquidarMassa()"
          />
        </q-card-section>
        <q-separator />

        <q-card-section>
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label>{{ liquidarMassa.quantidade }} itens selecionados</q-item-label>
                <q-item-label caption>Quantidade</q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>R$ {{ liquidarMassa.valorTotal | currency }}</q-item-label>
                <q-item-label caption>Valor</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-section>
          <div>
            <q-input
              bottom-slots
              label="Data de Liquidação"
              :error="Boolean(errorsLiquidacaoMassa.liquidacao)"
              v-model="liquidarMassa.liquidacao"
              @click="$refs.qDateProxyLiquidacao.show();"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                    ref="qDateProxyLiquidacao"
                  >
                    <q-date
                      minimal
                      today-btn
                      mask="DD/MM/YYYY"
                      :locale="langDate"
                      @input="() => $refs.qDateProxyLiquidacao.hide()"
                      v-model="liquidarMassa.liquidacao"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
              <template v-slot:error>
                <span v-for="(error, i) in errorsLiquidacaoMassa.liquidacao" :key="error + i">{{ error }}</span>
              </template>
            </q-input>
          </div>

          <div>
            <q-checkbox
              color="green"
              label="Utilizar saldo do cliente"
              v-model="utilizarSaldoCliente"
            />

            <div v-show="utilizarSaldoCliente && !loadingSaldoCliente">
              <div>
                Saldo Atual:
                <span :class="classSaldoAtualCliente">
                  R$ {{ saldoAtualCliente | currency }}
                </span>
              </div>
              <div>
                Saldo Após a Liquidação:
                <span :class="classNovoSaldoCliente">
                  R$ {{ novoSaldoCliente | currency }}
                </span>
              </div>
            </div>
            <div v-if="loadingSaldoCliente">
              <q-spinner color="grey-10" size="1em" />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            clickable
            label="Salvar"
            color="positive"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingSalvarLiquidarMassa"
            @click="salvarLiquidarMassa()"
          >
            <template v-slot:loading>
              <q-spinner color="grey-10" size="1em" />
            </template>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <modal-liquidar-movconta :tipo-conta="tipo"></modal-liquidar-movconta>
    <modal-detalhar-movconta></modal-detalhar-movconta>

    <q-dialog v-model="openDialogLimite" persistent>
      <q-card style="width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Limite de Crédito</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModalLimite()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <q-field
            bottom-slots
            label="Limite"
            v-model="limiteForm.valor"
            :error="Boolean(limiteErrors.valor)"
          >
            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
              <money
                class="q-field__native text-right"
                :id="id"
                :value="value"
                @input="emitValue"
                v-show="floatingLabel"
                v-bind="moneyFormat"
              >
              </money>
            </template>
            <template v-slot:error>
              <span v-for="(error, i) in limiteErrors.valor" :key="error + i">
                {{ error }}
              </span>
            </template>
          </q-field>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingSalvarLimite"
            @click="salvarLimite()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    
    <div>
      <q-page-sticky position="top-right" :offset="[0, 90]">
        <q-btn
          :color="openOpcoesAvancadasExtratoCliente ? 'red-7' : 'blue-7'"
          :icon="openOpcoesAvancadasExtratoCliente ? 'close' : 'settings'"
          class="rotate-0"
          style="border-top-left-radius: 50px;border-bottom-left-radius: 50px;"
          @click="openOpcoesAvancadasExtratoCliente = !openOpcoesAvancadasExtratoCliente"
        >
          <q-tooltip
            :content-class="
              openOpcoesAvancadasExtratoCliente
                ? 'bg-negative text-white text-center'
                : 'bg-secondary text-white text-center'
            "
            >{{
              openOpcoesAvancadasExtratoCliente ? "Fechar Opções Avançadas" : "Opções Avançadas"
            }}</q-tooltip
          >
        </q-btn>
      </q-page-sticky>
      <q-drawer
        side="right"
        v-model="openOpcoesAvancadasExtratoCliente"
        bordered
        :width="300"
        :breakpoint="500"
        behavior="default"
        content-class="bg-white"
      >
        <q-scroll-area class="fit">
          <div class="q-pa-sm">
            <div class="q-table__title">Opções Avançadas</div>
            <div>
                <q-list
                  class="rounded-borders"
                  style="max-width: 350px; margin-left: -10px;width: 300px;"
                >
                  <q-item-label header></q-item-label>
    
                  <q-item clickable v-ripple @click.exact="ajustarLimiteMassa()">
                    <q-item-section>
                      <q-item-label lines="1">Alterar Limite em Massa</q-item-label>
                      <q-item-label caption>Ajuste o limite de crédito de todos os clientes</q-item-label>
                    </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-scroll-area>
      </q-drawer>
    </div>
    <q-dialog v-model="openDialogLimiteMassa" persistent>
      <q-card style="width: 400px">
        <q-card-section class="row items-center">
          <div class="text-h6">Alterar Limite de Saldo em Massa</div>
          <q-space />
          <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            @click="closeModalLimiteMassa()"
          />
        </q-card-section>

        <q-separator />

        <q-card-section>
          <q-field
            bottom-slots
            label="Limite"
            v-model="limiteMassaForm.valor"
            :error="Boolean(limiteMassaErrors.valor)"
          >
            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
              <money
                class="q-field__native text-right"
                :id="id"
                :value="value"
                @input="emitValue"
                v-show="floatingLabel"
                v-bind="moneyFormat"
              >
              </money>
            </template>
            <template v-slot:error>
              <span v-for="(error, i) in limiteMassaErrors.valor" :key="error + i">
                {{ error }}
              </span>
            </template>
          </q-field>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            no-caps
            v-ripple
            clickable
            label="Salvar"
            color="positive"
            text-color="white"
            style="margin-right: 22px; margin-bottom: 10px"
            :loading="loadingSalvarLimiteMassa"
            @click="salvarLimiteMassa()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>

</template>

<script>
import axios from "@/axios-auth";
import moment from "moment";
import { bus } from "@/main";
import { Money } from 'v-money';
import { mapGetters } from "vuex";

export default {
  name: "extrato-cliente",
  meta: {
    title: "DSB | Extrato do Cliente",
  },
  components: { Money },
  data() {
    return {
      isPermissaoExtratoCliente: true,
      liquidadas: [],
      dtini: moment()
        .startOf("week")
        .format("DD/MM/YYYY"),
      dtfim: moment()
        .endOf("week")
        .format("DD/MM/YYYY"),
      cadastro: null,
      cadastro_id: null,
      abertas: [],
      abertasSelecionadas: [],
      tipo: null,
      carregandoExtrato: false,
      hasReachedLimit: false,
      loadingImprimir: false,
      carregando: false,
      carregandoForm: false,
      options: [],
      autocompleteResult: null,
      autocompleteResultForm: null,
      pagination: {
        sortBy: "nome",
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      abertasColumns: [
        {
          name: "vencimento",
          required: true,
          label: "Vencimento",
          align: "center",
          field: row => row.vencimento,
          format: val => `${val}`,
          sortable: false
        },
        {
          name: "descricao",
          required: true,
          label: "Descrição",
          align: "center",
          field: row => row.descricao,
          format: val => `${val}`,
          sortable: false
        },
        {
          name: "valor",
          required: true,
          label: "Valor",
          align: "center",
          field: row => row.valor,
          format: val => `${val}`,
          sortable: false
        },
        { name: "tdFora" }
      ],
      liquidadasColumns: [
        {
          name: "tipo",
          required: true,
          label: "Tipo",
          align: "center",
          field: row => row.tipo,
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "liquidacao",
          required: true,
          align: "center",
          label: "Data",
          field: row => row.liquidacao,
          sortable: true
        },
        {
          name: "descricao",
          required: true,
          align: "center",
          label: "Descrição",
          field: row => row.descricao,
          sortable: true
        },
        {
          name: "produtos",
          required: true,
          align: "center",
          label: "Produtos",
          field: row => row.movcaixa_id,
          sortable: true
        },
        {
          name: "entrada",
          required: true,
          align: "center",
          label: "Entrada",
          field: row => row.entrada,
          sortable: true
        },
        {
          name: "saida",
          required: true,
          align: "center",
          label: "Saída",
          field: row => row.saida,
          sortable: true
        },
        {
          name: "saldo",
          required: true,
          align: "center",
          label: "Saldo",
          field: row => row.saldo,
          sortable: true
        },
        { name: "tdFora" }
      ],
      saldoAtual: 0.00,
      movcontasCliente: this.newMovcontasCliente(),
      openDialogAddMovcontasCliente: false,
      movcontasClienteErrors: {},
      loadingSalvarMovcontasCliente: false,
      moneyFormat: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      loadingLiquidarMassa: false,
      loadingSalvarLiquidarMassa: false,
      openDialogLiquidarMassa: false,
      errorsLiquidacaoMassa: {},
      liquidarMassa: {
        quantidade: 0,
        valorTotal: 0,
        liquidacao: moment().format("DD/MM/YYYY"),
      },
      utilizarSaldoCliente: false,
      loadingSaldoCliente: false,
      saldoAtualCliente: 0.00,
      contas: [],
      tiposMovcontas: [
        {
          label: "Adicionar",
          value: 2,
        },
        {
          label: "Subtrair",
          value: 1,
        },
      ],
      hasVideo: false,
      openDialogLimite: false,
      openOpcoesAvancadasExtratoCliente: false,
      loadingAjustarLimiteMassa: false,
      loadingSalvarLimite: false,
      limiteForm: {
        valor: 0.00
      },
      limiteErrors: {},
      openDialogLimiteMassa: false,
      loadingSalvarLimiteMassa: false,
      limiteMassaForm: {
        valor: 0.00
      },
      limiteMassaErrors: {},
    };
  },
  methods: {
    detalharMovcontasCliente(movcontasCliente) {
      const data = {
        id: movcontasCliente.movconta_id,
      };
      this.$store.dispatch("detalharReceitas", data);
    },
    liquidar(value) {
      const movconta = JSON.parse(JSON.stringify(value));

      const { id, nome } = this.autocompleteResult;

      movconta.cadastro_id = id;
      movconta.cadastro_nome = nome;
      movconta.cadastro = { id, nome };

      if (movconta.tipo === 1) {
        bus.$emit("tipo", "despesa");
        this.$store.dispatch("liquidarDespesas", movconta);
      } else {
        bus.$emit("tipo", "receita");
        this.$store.dispatch("liquidarReceitas", movconta);
      }
    },
    openModalLiquidarMassa() {
      if (this.loadingLiquidarMassa) return;

      this.loadingLiquidarMassa = true;

      const data = {
        ids: this.abertasSelecionadas.map(aberta => aberta.id),
        action: 'liquidar-massa',
      };

      axios
        .post('/financeiro/edit-multiple/receita', data)
        .then(res => {
          this.loadingLiquidarMassa = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.utilizarSaldoCliente = true;
          this.loadSaldoCliente();

          this.errorsLiquidacaoMassa = {};
          this.liquidarMassa = {
            quantidade: res.data.quantidade,
            valorTotal: res.data.valor_total,
            liquidacao: moment().format('DD/MM/YYYY'),
          };
          this.openDialogLiquidarMassa = true;
        })
        .catch(() => {
          this.loadingLiquidarMassa = false;
          this.$q.notify({
            message: 'Houve um erro ao buscar as receitas',
            color: "red",
            position: "top-right",
          });
        });
    },
    closeModalLiquidarMassa() {
      this.utilizarSaldoCliente = false;
      this.openDialogLiquidarMassa = false;
    },
    salvarLiquidarMassa() {
      if (this.loadingSalvarLiquidarMassa) return;

      this.loadingSalvarLiquidarMassa = true;

      const data = {
        ids: this.abertasSelecionadas.map(aberta => aberta.id),
        action: 'liquidar-massa',
        confirmar: 1,
        liquidacao: this.liquidarMassa.liquidacao,
        utilizar_saldo_cliente: this.utilizarSaldoCliente,
        cadastro_id: this.cadastro_id
      };

      axios
        .post('/financeiro/edit-multiple/receita', data)
        .then(res => {
          this.loadingSalvarLiquidarMassa = false;

          this.$q.notify({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) {
            this.errorsLiquidacaoMassa = res.data.errors || {};
            return;
          }

          this.loadExtrato();
          this.closeModalLiquidarMassa();
        })
        .catch(() => {
          this.loadingSalvarLiquidarMassa = false;
          this.$q.notify({
            message: 'Houve um erro ao liquidar as receitas',
            color: "red",
            position: "top-right",
          });
        });
    },
    excluir(movconta) {
      movconta = JSON.parse(JSON.stringify(movconta));
      this.$store.dispatch("excluirReceitas", movconta);
    },
    changeFilterPeriodo(val) {
      const dtini = moment(this.dtini, "DD/MM/YYYY");
      const dtfim = moment(this.dtfim, "DD/MM/YYYY");

      if (val === "prev") {
        dtini.subtract(1, "weeks");
        dtfim.subtract(1, "weeks");
      }

      if (val === "next") {
        dtini.add(1, "weeks");
        dtfim.add(1, "weeks");
      }

      this.dtini = dtini.format("DD/MM/YYYY");
      this.dtfim = dtfim.format("DD/MM/YYYY");

      this.loadExtrato();
    },
    redirecionarMovcontas(value) {
      const liquidacao = moment(value.liquidacao, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );

      const filtros = {
        busca: "",
        dtini: liquidacao,
        dtfim: liquidacao,
        status: "Liquidado",
        gateway_id: value.gateway_id,
        liquidacao: "1",
        cadastro_id: this.cadastro_id,
      };

      const routeData = this.$router.resolve({
        name: value.tipo === 1 ? "despesas" : "receitas",
        query: filtros
      });
      window.open(routeData.href, "_blank");
    },
    loadContas() {
      axios
        .get('/contas')
        .then(res => {
          if (!res.data.success) throw new Error();

          this.contas = res.data.contas;
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    openModalAddMovcontasCliente() {
      this.movcontasClienteErrors = {};
      this.autocompleteResultForm = null;
      this.movcontasCliente = this.newMovcontasCliente();

      if (this.autocompleteResult) {
        this.autocompleteResultForm = {
          id: this.autocompleteResult.id,
          name: this.autocompleteResult.nome,
        };
        this.movcontasCliente.cadastro_id = this.autocompleteResultForm.id;
      }

      this.openDialogAddMovcontasCliente = true;

      this.loadContas();
      return;
    },
    closeModalAddMovcontasCliente() {
      this.openDialogAddMovcontasCliente = false;
    },
    estornarMovcontasCliente(movcontasCliente) {
      const data = {
        id: movcontasCliente.movconta_id,
      };
      this.$store.dispatch("estornarReceitas", data);
    },
    newMovcontasCliente() {
      return {
        tipo: 2,
        valor: 0.00,
        descricao: '',
        liquidacao: moment().format('DD/MM/YYYY'),
        movconta_id: null,
        cadastro_id: null,
        conta_id: null,
      };
    },
    addMovcontasCliente() {
      if (this.loadingSalvarMovcontasCliente) return;

      const data = JSON.parse(JSON.stringify(this.movcontasCliente));
      const url = '/financeiro/add-movcontas-cliente';

      this.loadingSalvarMovcontasCliente = true;

      axios
        .post(url, data)
        .then(res => {
          this.loadingSalvarMovcontasCliente = false;

          if (!res.data.success) {
            this.movcontasClienteErrors = res.data.errors || {},

            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.closeModalAddMovcontasCliente();
          this.loadExtrato();
        })
        .catch(() => {
          this.loadingSalvarMovcontasCliente = false;
          this.$q.notify({
            message: 'Houve um erro ao adicionar/subtrair o saldo do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    extratoCliente(id = null) {
      if (this.carregandoExtrato) return;

      const cadastroId = id ? JSON.parse(String(id)) : null;
      if (!cadastroId) return;

      this.carregandoExtrato = true;

      const dtini = moment(this.dtini, "DD/MM/YYYY").format("DD-MM-YYYY");
      const dtfim = moment(this.dtfim, "DD/MM/YYYY").format("DD-MM-YYYY");

      axios
        .get(`/financeiro/extrato-cliente/${cadastroId}`, {
          params: {
            dtini: dtini,
            dtfim: dtfim,
          },
        })
        .then(res => {
          window.history.pushState(
            { cadastro_id: cadastroId },
            "cliente",
            cadastroId ? `?cadastro_id=${cadastroId}` : '?',
          );
          this.$router.history.current.query.cadastro_id = cadastroId;
          this.cadastro_id = cadastroId;

          this.abertasSelecionadas = [];
          this.abertas = res.data.abertas;
          this.liquidadas = res.data.extrato;
          this.hasReachedLimit = res.data.has_reached_limit;
          this.autocompleteResult = res.data.cliente;
          this.saldoAtual = res.data.saldo_atual;
          this.carregandoExtrato = false;
          this.isPermissaoExtratoCliente = true;
          this.limiteForm.valor = res.data.cliente.limite;
        })
        .catch(() => {
          this.$q.notify({
            message: "Não foi possivel carregar extrato bancário do cliente",
            color: "red",
            position: "top-right"
          });

          this.carregandoExtrato = false;
          this.isPermissaoExtratoCliente = false;
        });
    },
    imprimir() {
      const dtini = moment(this.dtini, "DD/MM/YYYY").format("DD-MM-YYYY");
      const dtfim = moment(this.dtfim, "DD/MM/YYYY").format("DD-MM-YYYY");

      if (!this.cadastro_id) {
        this.$q.notify({
          message:
            "Não foi possível gerar relátorio, cliente não foi encontrado!",
          color: "green",
          position: "top-right"
        });
        return;
      }

      this.loadingImprimir = true;

      axios
        .post(
          `/financeiro/extrato-cliente/${this.cadastro_id}`,
          {
            tipo: "imprimir"
          },
          {
            headers: {
              accept: "text/html, application/json"
            },
            params: {
              dtini: dtini,
              dtfim: dtfim
            }
          }
        )
        .then(res => {
          this.loadingImprimir = false;
          let openWindow = window.open("/");
          openWindow.document.write(res.data);
        })
        .catch(() => {
          this.loadingImprimir = false;

          this.$q.notify({
            message:
              "Não foi possível contactar, ou você não possui permissão!",
            color: "red",
            position: "top-right"
          });
        });
    },
    returnIcons(value) {
      switch (value) {
        case 1:
          return "remove_circle_outline";
          break;
        case 2:
          return "add_circle_outline";
          break;
        case 6:
          return "bookmark";
          break;
      }
    },
    returnColors(value) {
      switch (value) {
        case 1:
          return "color3";
          break;
        case 2:
          return "color2";
          break;
        case 6:
          return "color7";
          break;
      }
    },
    returnDescricao(value) {
      if (value.length > 40) {
        return value.substr(0, 40) + "...";
      }
      return value;
    },
    // Funções do autocomplete
    filterAutocomplete(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregando = true;
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.carregando = false;
          this.options = res.data;
        });
      });
    },
    filterAutocompleteForm(val, update, abort) {
      update(() => {
        if (val === null) return abort();
        this.carregandoForm = true;
        axios.get("/cadastros/busca?term=" + val).then(res => {
          this.carregandoForm = false;
          this.options = res.data;
        });
      });
    },
    formatDisplay(result) {
      return result.label;
    },
    resultEmitRecebe(result) {
      if (!result || result.id == null) {
        this.resetExtrato();
        this.limiteForm = {
          valor: 0.00
        };

        const params = this.$router.history.current.query;
        if (params.cadastro_id) {
          this.$router.push({ query: { ...params, cadastro_id: null } });
        }

        return;
      }

      this.$q.notify({
        message: result.name + " foi selecionado.",
        color: "green",
        position: "top-right"
      });
      this.cadastro = result;
      this.cadastro_id = result.id;
    },
    resultEmitRecebeMovcontasCliente(result) {
      if (result && result.id != null) {
        this.$q.notify({
          message: result.name + " foi selecionado.",
          color: "green",
          position: "top-right"
        });
        this.movcontasCliente.cadastro_id = result.id;
      }
    },
    displayMontar(value) {
      return !value ? value : value.name;
    },
    selecionarData(ref) {
      this.$refs[ref].hide();
      if (!this.cadastro && !this.cadastro_id) return;

      this.loadExtrato();
    },
    loadExtrato() {
      const params = this.$router.history.current.query;
      const cadastroId = this.cadastro_id > 0 ? this.cadastro_id : params.cadastro_id;

      if (!cadastroId) return;

      this.extratoCliente(cadastroId);
    },
    loadSaldoCliente() {
      if (!this.extratoClienteInstalado || this.loadingSaldoCliente) return;

      this.loadingSaldoCliente = true;

      axios
        .get(`/financeiro/extrato-cliente/${this.cadastro_id}?apenas_saldo=1`)
        .then(res => {
          this.loadingSaldoCliente = false;

          if (!res.data.success) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.saldoAtualCliente = res.data.saldo_atual;
        })
        .catch(() => {
          this.loadingSaldoCliente = false;
          this.$q.notify({
            message: 'Houve um erro ao consultar o extrato do cliente',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    resetExtrato() {
      this.abertas = [];
      this.liquidadas = [];
      this.autocompleteResult = null;
      this.saldoAtual = 0.00;
      this.cadastro_id = null;
      this.cadastro = null;
    },
    modalIntro() {
      this.$store.dispatch("loadManual", "extrato_cliente");
    },
    verificarPrimeiroVideo() {
      if (!this.videosManuais) return;

      this.hasVideo = this.manuaisDisponiveis.some(item => item === "extrato_cliente");

      if (!this.videosManuais.video_extrato_cliente) {
        this.modalIntro();
      }
    },
    openModalLimite() {
      if (this.cadastro_id == null) {
        this.$q.notify({
          message: 'Selecione um cliente para ajustar o limite de saldo',
          color: 'orange',
          position: 'top-right',
        });
        return;
      }

      this.limiteErrors = {};
      this.openDialogLimite = true;
    },
    closeModalLimite() {
      this.openDialogLimite = false;
    },
    salvarLimite() {
      if (this.loadingSalvarLimite) return;

      if (this.cadastro_id == null) {
        this.$q.notify({
          message: 'Selecione um cliente para ajustar o limite de saldo',
          color: 'orange',
          position: 'top-right',
        });
        return;
      }

      const data = {
        action: 'ajustar_limite_saldo',
        limite: this.limiteForm.valor,
      };

      axios
        .post(`/financeiro/limite/${this.cadastro_id}`, data)
        .then(res => {
          this.loadingSalvarLimite = false;

          if (!res.data.success) {
            this.limiteErrors = res.data.errors || {};

            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          this.$q.notify({
            message: res.data.message,
            color: 'green',
            position: 'top-right',
          });

          this.closeModalLimite();
        })
        .catch(() => {
          this.loadingSalvarLimite = false;

          this.$q.notify({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    ajustarLimiteMassa() {
      if (this.loadingAjustarLimiteMassa) return;
      
      this.limiteMassaErrors = {};
      this.limiteMassaForm = {
        valor: 0.00
      };
      this.openDialogLimiteMassa = true;
    },
    closeModalLimiteMassa() {
      this.openDialogLimiteMassa = false;
    },
    salvarLimiteMassa() {
      if (this.loadingSalvarLimiteMassa) return;

      const data = {
        limite: this.limiteMassaForm.valor,
      };

      this.$q.dialog({
        title: "Ajustar Limite de Saldo em Massa",
        message: "Deseja realmente ajustar o limite de saldo de todos os clientes?",
        cancel: "Cancelar",
        persistent: true
      })
      .onOk(() => {
        this.loadingSalvarLimiteMassa = true;

        axios
          .post('/financeiro/limite', data)
          .then(res => {
            this.loadingSalvarLimiteMassa = false;

            if (!res.data.success) {
              this.limiteMassaErrors = res.data.errors || {};

              this.$q.notify({
                message: res.data.message,
                color: 'orange',
                position: 'top-right',
              });
              return;
            }

            this.$q.notify({
              message: res.data.message,
              color: 'green',
              position: 'top-right',
            });

            this.closeModalLimiteMassa();
            this.loadExtrato();
          })
          .catch(() => {
            this.loadingSalvarLimiteMassa = false;

            this.$q.notify({
              message: 'Não foi possível contactar, ou você não possui permissão!',
              color: 'red',
              position: 'top-right',
            });
          });
      });
    },
  },
  watch: {
    cadastro_id: function(newValue, oldValue) {
      if (String(newValue) != String(oldValue)) {
        this.extratoCliente(newValue);
      }
    },
  },
  computed: {
    ...mapGetters([
      'extratoClienteInstalado',
      'videosManuais',
      'manuaisDisponiveis'
    ]),
    abertasTotal() {
      return this.abertas.reduce((total, movconta) => total + parseFloat(movconta.valor), 0);
    },
    abertasSelecionadasTotal() {
      return this.abertasSelecionadas.reduce((total, movconta) => total + parseFloat(movconta.valor), 0);
    },
    novoSaldoCliente() {
      return this.saldoAtualCliente - parseFloat(this.liquidarMassa.valorTotal);
    },
    classSaldoAtualCliente() {
      if (this.saldoAtualCliente > 0) return 'text-green';
      if (this.saldoAtualCliente < 0) return 'text-red';

      return '';
    },
    classNovoSaldoCliente() {
      if (this.novoSaldoCliente > 0) return 'text-green';
      if (this.novoSaldoCliente < 0) return 'text-red';

      return '';
    },
  },
  destroyed() {
    bus.$off("loadExtratoCliente");
  },
  mounted() {
    this.loadExtrato();
    this.verificarPrimeiroVideo();

    bus.$on("loadExtratoCliente", () => {
      this.loadExtrato();
    });
  },
};
</script>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
}

.align {
  display: flex;
  align-items: center;
}

.color2 {
  color: #34a853;
}

.color3 {
  color: #ea4335;
}

.color7 {
  color: #808080;
}
</style>
