<template>
  <q-page id="relatorio">
    <div class="col-12 col-md-12 text-center" v-if="buscando">
      <q-spinner color="blue-7" size="4em" />
    </div>

    <q-card class="bg-white" v-if="!buscando && erro">
      <q-card-section>
        <div class="text-subtitle2">{{ erro }}</div>
      </q-card-section>
    </q-card>

    <q-card class="bg-white" v-if="!buscando && !erro">
      <q-card-section>
        <div class="flex items-center">
          <q-btn flat round class="q-mr-sm" icon="arrow_back" @click="historicoShow()" />
          <div class="text-h6">{{ relatorio.nome }}</div>
        </div>
        <div class="text-subtitle2" v-html="relatorio.descricao"></div>
      </q-card-section>

      <q-card-actions>
        <div style="margin-right: 10px" v-for="(filtro, key) in relatorio.filtros" :key="key">
          <div v-if="filtro.autocomplete && filtro.autocomplete.isAtivo">
            <q-select
              v-model="filtro.value"
              use-input
              input-debounce="500"
              :options="options"
              :option-value="value => value"
              :option-label="filtro.autocomplete.option_label"
              :label="filtro.labelUser"
              clearable
              @filter="
                (val, update, abort, url) => {
                  autocompletePersonalizar(val, update, abort, filtro.autocomplete.url);
                }
              "
              emit-value
              map-options
              style="max-width: 300px"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey"> Nenhum resultado encontrado! </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <q-input
            v-else-if="filtro.type != 'option'"
            v-model="filtro.value"
            :label="filtro.labelUser"
            @click="toggle(filtro)"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer" v-if="filtro.type === 'date'">
                <q-popup-proxy
                  :ref="'input' + filtro.labelUser"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="filtro.value"
                    mask="DD/MM/YYYY"
                    minimal
                    today-btn
                    @input="() => toggle(filtro, 'hide')"
                  />
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <q-select
            v-else
            v-model="filtro.value"
            class="option"
            emit-value
            :display-value="returnLabel(filtro)"
            :options="filtro.optionsFiltro"
            :multiple="filtro.multiple"
            :label="filtro.labelUser"
            clearable
            @clear="filtro.value = []"
            @filter="(val, update, abort) => filterFn(val, update, abort, filtro)"
            use-input
            input-debounce="0"
          />
        </div>
      </q-card-actions>
      <q-card-actions>
        <q-btn
          flat
          label="Visualizar Relatório"
          class="bg-green text-white"
          @click="visualizar()"
          :loading="loadRelatorio"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>
        <q-btn
          flat
          label="Exportar"
          class="bg-green text-white"
          @click="exportarRelatorioExcel"
          :loading="loadExportar"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>
        <q-btn
          flat
          label="Exportar PDF"
          class="bg-green text-white"
          @click="exportarRelatorioPdf"
          :loading="loadExportarPdf"
        >
          <template v-slot:loading>
            <q-spinner color="grey-10" size="1em" />
          </template>
        </q-btn>
      </q-card-actions>
    </q-card>
  </q-page>
</template>

<script>
import axios from '@/axios-auth';
import { Notify, scroll } from 'quasar';
import { bus } from '@/main';

export default {
  name: 'relatorio',
  meta: {
    title: 'DSB | Relatório',
  },
  data() {
    return {
      relatorio: [],
      data: {},
      loadRelatorio: false,
      loadExportar: false,
      loadExportarPdf: false,
      buscando: false,
      erro: null,
      options: [],
      filtroSelecionadoAutocomplete: {},
    };
  },
  methods: {
    returnLabel(filtro) {
      let option = filtro.options.filter(result => {
        return result.value === filtro.value;
      });

      if (option.length > 0) {
        return option[0].label;
      }

      let label = '';

      if (Array.isArray(filtro.value)) {
        const labels = [];

        filtro.value.forEach(value => {
          const optionFiltro = filtro.options.find(option => option.value === value);

          labels.push(optionFiltro.label);
        });

        label = labels.join(', ');
      } else {
        label = filtro.value;
      }

      return label;
    },
    toggle(input, tipo = 'show') {
      if (input.type != 'date') return;
      let label = 'input' + input.labelUser + '';

      if (tipo === 'show') {
        return this.$refs[label][0].show();
      }
      return this.$refs[label][0].hide();
    },
    returnType(type) {
      return type === 'string' ? 'text' : type;
    },
    buscaRelatorio() {
      this.buscando = true;
      this.erro = null;
      scroll.setScrollPosition(window, 0);

      axios
        .get('/relatorios/view/' + this.routeId)
        .then(res => {
          this.buscando = false;

          if (!res.data.success) {
            this.erro = res.data.message;
            return;
          }

          this.relatorio = res.data.relatorio;
        })
        .catch(() => {
          Notify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.buscando = false;
        });
    },
    filtrarDados() {
      let filtros = this.relatorio.filtros;

      if (!filtros) return;

      filtros.forEach(filtro => {
        if (filtro.value === null) return;

        // Condição para verificar filtro do autocomplete.
        if (filtro && filtro.autocomplete) {
          // Condição responsavel por verificar e selecionar option label do item.
          if (filtro.autocomplete.option_label) {
            this.data[filtro.label + '_' + filtro.autocomplete.option_label] =
              filtro.value[filtro.autocomplete.option_label];
          }
          /*
            Condição responsavel por verificar e selecionar option value do item.
            Ou seja, pegar o valor do emitido na seleção para ser utilizando na busca no SQL.
          */
          if (filtro.autocomplete.option_value) {
            /*
              Nesse caso, precisamos selecionar o label principal
              Que é a variável que vai guardar o valor selecionado pelo usuário.
            */
            this.data[filtro.label] = filtro.value[filtro.autocomplete.option_value];
          }
        } else {
          /**
           * Quanto tem multiplas opções precisamos retornar a label das opções selecionadas
           * para aparecer corretamente no relatório, caso contrário só vai aparecer o id selecionado
           */
          if (filtro.datasource && filtro.options) {
            if (filtro.multiple && Array.isArray(filtro.value)){
              this.data[`${filtro.label}_label`] =
                filtro.options.filter(opcao => filtro.value.includes(opcao.value)) //retorna um array de opções filtradas
                .map(opcao => opcao.label); // retorna um array de strings
                //.join(", ");

            } else {
              this.data[`${filtro.label}_label`] =
                filtro.options.filter(opcao => opcao.value == filtro.value) //retorna um array de opções filtradas
                .map(opcao => opcao.label); // retorna um array de strings
                //.join(", ");
            }
          }

          /*
            Nessa situação, são consultas básicas que não envolve acesso a atributos esepecificos.
          */
          this.data[filtro.label] = filtro.value;
        }
      });
    },
    visualizar() {
      this.loadRelatorio = true;
      this.filtrarDados();

      axios
        .post('/relatorios/view/' + this.routeId, this.data, {
          headers: {
            Accept: 'text/html',
          },
        })
        .then(res => {
          this.loadRelatorio = false;

          if (res.data.success === false) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          if (res.error) {
            throw res.error;
          }

          let windowJanela = window.open('about:blank');
          windowJanela.document.write(res.data);
          windowJanela.document.close();
        })
        .catch((e) => {
          if (e.message == "windowJanela is null"){
            Notify.create({
              message: 'O navegador bloqueou a janela do relatório!',
              color: 'red',
              position: 'top-right',
            });
          }else{
            Notify.create({
              message: 'Não foi possível contactar, ou você não possui permissão!',
              color: 'red',
              position: 'top-right',
            });
          }
          this.loadRelatorio = false;
        });
    },
    exportarRelatorioExcel() {
      this.loadExportar = true;
      this.filtrarDados();

      axios
        .post('/relatorios/view/' + this.routeId + '/?exportar=true', this.data, {
          headers: {
            Accept: 'application/json',
          },
        })
        .then(res => {
          this.loadExportar = false;

          if (res.data.success === false) {
            this.$q.notify({
              message: res.data.message,
              color: 'orange',
              position: 'top-right',
            });
            return;
          }

          Notify.create({
            message: res.data.message,
            color: 'green',
            position: 'top-right',
          });
          window.location.href = res.data.linkArquivo;
        })
        .catch(() => {
          Notify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loadExportar = false;
        });
    },
    exportarRelatorioPdf() {
      this.loadExportarPdf = true;
      this.filtrarDados();

      axios
        .post('/relatorios/view/' + this.routeId + '/?exportar_pdf=true', this.data, {
          headers: {
            Accept: 'text/html,application/json',
          },
        })
        .then(res => {
          this.loadExportarPdf = false;

          Notify.create({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;

          window.open(res.data.linkArquivo, 'blank');
        })
        .catch(() => {
          Notify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
          this.loadExportarPdf = false;
        });
    },
    filterFn(val, update, abort, filtro) {
      update(() => {
        const valor = val.toLowerCase();

        filtro.optionsFiltro = filtro.options.filter(
          option => option.label.toLowerCase().indexOf(valor) > -1
        );
        this.$forceUpdate();
      });
    },
    autocompletePersonalizar(val, update, abort, url) {
      update(() => {
        if (val === null) return abort();
        axios.get(url + val).then(res => {
          this.options = res.data;
        });
      });
    },
    historicoShow() {
      this.$router.push('/relatorios');
      bus.$emit('getHistoricoRelatorios');
    },
  },
  computed: {
    routeId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.buscaRelatorio();
  },
  watch: {
    routeId() {
      this.buscaRelatorio();
    },
    relatorio(relatorio) {
      if (!relatorio || !Array.isArray(relatorio.filtros)) {
        return;
      }

      relatorio.filtros.forEach(filtro => {
        if (filtro.type === 'option') {
          filtro.optionsFiltro = filtro.options;
        }
      });
    },
  },
};
</script>

<style lang="stylus">
.option{
  width:200px;
}
</style>
