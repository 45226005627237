<template>
  <q-page id="saas">
    <div class="q-pa-md">
      <sem-permissao v-if="semPermissao"></sem-permissao>
      <div class="q-col-gutter-md row" v-else>
        <div class="col-12 row items-center" style="margin-left: 10px; padding-right: 20px">
          <small style="color: #616161">
            <q-btn-group flat>
              <q-btn flat round icon="chevron_left" class="white text-blue-7" clickable @click="changeMonth('prev')"
                :disable="loading || !dados" />
              <q-btn flat :label="returnMonth()" class="white text-blue-7" />
              <q-btn flat round icon="chevron_right" class="white text-blue-7" clickable @click="changeMonth('next')"
                :disable="loading || !dados || !dados.nextMonth" />
            </q-btn-group>
          </small>
          <q-card v-if="dados.dados_atuais && !loading" class="note-card q-ml-md">
            <q-card-section class="q-py-sm">Os dados atuais são projeções</q-card-section>
          </q-card>

          <q-space />

          <autocomplete class="q-mr-md" title="Selecionar Cadastro" :filter="filterCadastro"
            :carregando="carregandoCadastro" :options="optionsCadastros" :formatDisplay="formatDisplay"
            :create="createValue" @resultAutocomplete="resultEmitRecebe" :display="displayMontar"
            tipoConsulta="cadastro" :cadastro="abreCadastro" />
          <q-btn flat dense :icon="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'" class="text-black"
            clickable @click="toogleFullscreen()">
            <template v-slot:default>
              <q-tooltip>Tela Cheia</q-tooltip>
            </template>
          </q-btn>
        </div>

        <div class="col-12 col-md-12 text-center" v-if="loading">
          <q-spinner color="blue-7" size="4em" />
        </div>

        <div class="col-12" v-else>
          <div class="row q-col-gutter-md">
            <!-- MRR -->
            <div class="col-12 col-md-4 col-lg-2">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">MRR</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      O MRR é a receita recorrente mensal que seu negócio de assinaturas possui
                    </q-tooltip>
                  </q-icon>
                </q-card-section>

                <q-card-section class="q-pt-none q-pb-xs">
                  <q-list v-if="dados.mrr">
                    <q-item>
                      <q-item-section avatar class="items-center">
                        <q-icon :color="dados.mrr.porcentagem_atual >= 0 ? 'positive' : 'negative'"
                          :name="`fa fa-level-${dados.mrr.porcentagem_atual >= 0 ? 'up' : 'down'}`" size="24px" />
                        <q-item-label caption>{{ dados.mrr.porcentagem_atual }}%</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label>{{ dados.mrr.mes_atual | currency }}</q-item-label>
                        <q-item-label caption>MRR Atual</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item>
                      <q-item-section avatar class="items-center">
                        <q-icon :color="dados.mrr.porcentagem_anterior >= 0 ? 'positive' : 'negative'" :name="`fa fa-level-${dados.mrr.porcentagem_anterior >= 0 ? 'up' : 'down'
                          }`" size="24px" />
                        <q-item-label caption>{{ dados.mrr.porcentagem_anterior }}%</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label>{{ dados.mrr.mes_anterior | currency }}</q-item-label>
                        <q-item-label caption>MRR de {{ getNomeMes(-1) }}</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item>
                      <q-item-section avatar class="items-center">
                        <q-icon :color="dados.mrr.porcentagem_anterior2 >= 0 ? 'positive' : 'negative'" :name="`fa fa-level-${dados.mrr.porcentagem_anterior2 >= 0 ? 'up' : 'down'
                          }`" size="24px" />
                        <q-item-label caption>{{ dados.mrr.porcentagem_anterior2 }}%</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label>{{ dados.mrr.mes_anterior2 | currency }}</q-item-label>
                        <q-item-label caption>MRR de {{ getNomeMes(-2) }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-card-section>
              </q-card>
            </div>

            <!-- Growth Rate / Churn Rate -->
            <div class="col-12 col-md-4" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Growth / Churn Rate</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      O growth é a soma de todas as novas recorrências ou expansões do sistema neste
                      mês. <br />
                      O churn é a soma de todas as recorrências canceladas ou contrações do sistema
                      neste mês.
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section v-if="graficoGrowthChurnData" class="q-pt-none q-pb-xs">
                  <AreaChart :series="graficoGrowthChurnData" :options="graficoGrowthChurnOptions" :height="200" />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>
              </q-card>
            </div>

            <!-- MRR Mês a Mês -->
            <div :class="cadastro ? 'col-12 col-md-8 col-lg-6' : 'col-12 col-md-4'">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">MRR (Mês a Mês)</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip> O gráfico do MRR final ao longo dos meses no último ano </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs" v-if="dados.mrr_mes_mes && dados.mrr_mes_mes.length > 0">
                  <AreaChart :series="graficoArrData" :height="200" />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>
              </q-card>
            </div>

            <!-- Ticket Médio -->
            <div class="col-12 col-md-4 col-lg-2">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Ticket Médio</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      O ticket médio é o total da receita recorrente dividido pela quantidade de
                      clientes ativos <br />
                      O ticket médio MRR é o total da receita recorrente dividido pela quantidade de
                      assinaturas ativos
                    </q-tooltip>
                  </q-icon>
                </q-card-section>

                <q-card-section class="q-pt-none q-pb-xs">
                  <q-list v-if="dados.ticket_medio && dados.ticket_medio_mrr">
                    <q-item>
                      <q-item-section avatar class="items-center">
                        <q-icon :color="dados.ticket_medio_mrr.porcentagem_atual >= 0 ? 'positive' : 'negative'
                          " :name="`fa fa-level-${dados.ticket_medio_mrr.porcentagem_atual >= 0 ? 'up' : 'down'
                            }`" size="24px" />
                        <q-item-label caption>{{ dados.ticket_medio_mrr.porcentagem_atual }}%</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label>{{
                          dados.ticket_medio_mrr.mes_atual | currency
                          }}</q-item-label>
                        <q-item-label caption>MRR Atual</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item>
                      <q-item-section avatar class="items-center">
                        <q-icon :color="dados.ticket_medio_mrr.porcentagem_anterior >= 0
                          ? 'positive'
                          : 'negative'
                          " :name="`fa fa-level-${dados.ticket_medio_mrr.porcentagem_anterior >= 0 ? 'up' : 'down'
                            }`" size="24px" />
                        <q-item-label caption>{{ dados.ticket_medio_mrr.porcentagem_anterior }}%</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label>{{
                          dados.ticket_medio_mrr.mes_anterior | currency
                          }}</q-item-label>
                        <q-item-label caption>MRR de {{ getNomeMes(-1) }}</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-separator v-show="!cadastro" />

                    <q-item v-show="!cadastro">
                      <q-item-section avatar class="items-center">
                        <q-icon :color="dados.ticket_medio.porcentagem_atual >= 0 ? 'positive' : 'negative'
                          " :name="`fa fa-level-${dados.ticket_medio.porcentagem_atual >= 0 ? 'up' : 'down'
                            }`" size="24px" />
                        <q-item-label caption>{{ dados.ticket_medio.porcentagem_atual }}%</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label>{{ dados.ticket_medio.mes_atual | currency }}</q-item-label>
                        <q-item-label caption>por Cliente Atual</q-item-label>
                      </q-item-section>
                    </q-item>

                    <q-item v-show="!cadastro">
                      <q-item-section avatar class="items-center">
                        <q-icon :color="dados.ticket_medio.porcentagem_anterior >= 0 ? 'positive' : 'negative'
                          " :name="`fa fa-level-${dados.ticket_medio.porcentagem_anterior >= 0 ? 'up' : 'down'
                            }`" size="24px" />
                        <q-item-label caption>{{ dados.ticket_medio.porcentagem_anterior }}%</q-item-label>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label>{{
                          dados.ticket_medio.mes_anterior | currency
                          }}</q-item-label>
                        <q-item-label caption>por Cliente de {{ getNomeMes(-1) }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-card-section>
              </q-card>
            </div>

            <!-- Gráfico Lifetime Value (LTV) -->
            <div class="col-12 col-md-4" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Gráfico Lifetime Value (LTV)</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      O gráfico do lifetime value ao longo dos meses no último ano
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs"
                  v-if="dados.lifetime_value_graph && dados.lifetime_value_graph.length > 0">
                  <MixedChart :series="graficoLifetimeValueData" :height="200" />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>
              </q-card>
            </div>

            <!-- Lifetime Cliente (CLT) -->
            <div class="col-12 col-md-4 col-lg-2">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Lifetime Cliente</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      O tempo de vida médio em meses do cliente na empresa e seu valor
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs" v-if="dados.lifetime_clientes">
                  <h5 class="q-mb-md">
                    {{ dados.lifetime_clientes.mes_atual }}
                    <span style="font-size: 12px">meses</span> /
                    <span style="font-size: 12px">R$</span>
                    {{ dados.lifetime_clientes.mes_atual_value | currency }}
                  </h5>
                  <p>
                    <q-icon :color="dados.lifetime_clientes.porcentagem_value >= 0 ? 'positive' : 'negative'
                      " :name="`fa fa-level-${dados.lifetime_clientes.porcentagem_value >= 0 ? 'up' : 'down'
                        }`" />
                    {{ dados.lifetime_clientes.porcentagem_value }}% período anterior:<br />
                    <strong>{{ dados.lifetime_clientes.mes_anterior }}</strong> meses / R$
                    <strong>{{ dados.lifetime_clientes.mes_anterior_value | currency }}</strong>
                  </p>
                </q-card-section>
              </q-card>
            </div>

            <!-- Lifetime Value (LTV) -->
            <div class="col-12 col-md-4 col-lg-2" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Lifetime Value</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      O lifetime value (valor do tempo de vida do cliente) é uma métrica que diz
                      quanto tempo a empresa consegue se manter funcionando perdendo esta quantidade
                      de clientes
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs" v-if="dados.lifetime_value">
                  <h5 class="q-mb-md">
                    {{ dados.lifetime_value.mes_atual }}
                    <span style="font-size: 12px">meses</span> /
                    <span style="font-size: 12px">R$</span>
                    {{ dados.lifetime_value.mes_atual_value | currency }}
                  </h5>
                  <p>
                    <q-icon :color="dados.lifetime_value.porcentagem_value >= 0 ? 'positive' : 'negative'" :name="`fa fa-level-${dados.lifetime_value.porcentagem_value >= 0 ? 'up' : 'down'
                      }`" />
                    {{ dados.lifetime_value.porcentagem_value }}% período anterior:<br />
                    <strong>{{ dados.lifetime_value.mes_anterior }}</strong> meses / R$
                    <strong>{{ dados.lifetime_value.mes_anterior_value | currency }}</strong>
                  </p>
                </q-card-section>
              </q-card>
            </div>

            <!-- CAC Mês a Mês -->
            <div class="col-12 col-md-4" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">CAC (Mês a Mês)</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip> O gráfico do CAC ao longo dos meses no último ano </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs" v-if="dados.metricas_saas">
                  <AreaChart :series="graficoCacData" :height="200" />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>
              </q-card>
            </div>

            <!-- CAC -->
            <div class="col-12 col-md-4 col-lg-2" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">CAC</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      O custo de aquisição de clientes é a métrica que mostra quanto você investiu
                      para conquistar um novo cliente
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs" v-if="dados.cac">
                  <h5 class="q-mb-md">
                    <span style="font-size: 12px">R$</span> {{ dados.cac.mes_atual | currency }}
                  </h5>
                  <p>
                    <q-icon :color="dados.cac.porcentagem <= 0 ? 'positive' : 'negative'"
                      :name="`fa fa-level-${dados.cac.porcentagem >= 0 ? 'up' : 'down'}`" />
                    {{ dados.cac.porcentagem }}% período anterior:<br />
                    R$ <strong>{{ dados.cac.mes_anterior | currency }}</strong>
                  </p>
                </q-card-section>
              </q-card>
            </div>

            <!-- Índice LTV / CAC -->
            <div class="col-12 col-md-4 col-lg-2" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Índice LTV / CAC</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip> O índice é a divisão do LTV pelo CAC </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs" v-if="dados.indice_ltv_cac">
                  <h5 class="q-mb-md">
                    <span style="font-size: 12px"></span>{{ dados.indice_ltv_cac.mes_atual | currency }}
                  </h5>
                  <p>
                    <q-icon :color="dados.indice_ltv_cac.porcentagem >= 0 ? 'positive' : 'negative'"
                      :name="`fa fa-level-${dados.indice_ltv_cac.porcentagem >= 0 ? 'up' : 'down'}`" />
                    {{ dados.indice_ltv_cac.porcentagem }}% período anterior:<br />
                    <strong>{{ dados.indice_ltv_cac.mes_anterior | currency }}</strong>
                  </p>
                </q-card-section>
              </q-card>
            </div>

            <!-- Payback (Meses para Recuperar o CAC) -->
            <div class="col-12 col-md-4" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Payback (Meses para Recuperar o CAC)</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip>
                      Esta métrica mostra quantos meses você vai demorar para recuperar o que foi
                      investido na conquista de novos clientes
                    </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs" v-if="graficoPaybackData">
                  <LineChart :series="graficoPaybackData" :height="200" :options="{ xaxis: {} }" />
                </q-card-section>
              </q-card>
            </div>

            <!-- Histórico de clientes -->
            <div class="col-12 col-md-4" v-show="!cadastro">
              <q-card style="box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); border-radius: 10px;">
                <q-card-section class="row items-center q-pb-sm">
                  <div class="text-grey-9" style="font-size: 16px;">Histórico de Clientes</div>
                  <q-space />
                  <q-icon name="help_outline" color="grey-8" size="sm">
                    <q-tooltip> O gráfico dde clientes ao longo dos meses no último ano </q-tooltip>
                  </q-icon>
                </q-card-section>
                <q-card-section class="q-pt-none q-pb-xs"
                  v-if="dados.historico_clientes && dados.historico_clientes.length > 0">
                  <MixedChart :series="graficoHistoricoClientesData" :height="200" :options="{ yaxis: {} }" />
                </q-card-section>
                <q-card-section v-else>
                  <p class="text-center q-mt-lg">Não há dados</p>
                </q-card-section>
              </q-card>
            </div>
          </div>
        </div>

        <!-- Tabela de Métricas -->
        <div class="col-12" v-if="!loading" v-show="!cadastro">
          <q-table :data="dadosTable" :columns="columnsTable" :loading="loading" :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'" :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'" separator="cell" class="sticky-header-column-table">
            <template v-slot:bottom></template>
            <template v-slot:top>
              <div class="q-table__title">Métricas Financeiras SaaS</div>
              <q-space />
              <span class="text-caption text-grey-8" v-if="cadastro">
                A tabela de métricas não possui relação com o cadastro selecionado
              </span>
              <q-select emit-value map-options class="q-mr-md" style="width: 120px" label="Exibir últimos"
                v-model="quantidadeMesesTabela" :options="opcoesMesesTabela" />
              <q-btn flat no-caps color="primary" icon-right="archive" label="Exportar" @click="exportTable()" />
            </template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td align="left" key="legenda">
                  <strong>{{ props.row.legenda }}</strong>
                  <span class="text-grey-8"> {{ props.row.sublegenda }}</span>
                  <q-icon name="help_outline" class="float-right" color="grey-8" size="xs">
                    <q-tooltip>{{ props.row.descricao }}</q-tooltip>
                  </q-icon>
                </q-td>
                <q-td :align="props.row.align" v-for="i in columnsTable.length - 1" :key="`referencia${i}`" @click="
                  props.row.clickable
                    ? openDadosTd(props.row.campo, props.row[`referencia${i}_text`])
                    : null
                  " :class="{ 'cursor-pointer': props.row.clickable }">
                  <td-metricas-saas :index="i" :celula="props.row" />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>

        <!-- Tabela de Trials -->
        <div class="col-12" v-if="!loading && opcoesRecorrencias.integracoes_piperun_utilizar_trial === '1'"
          v-show="!cadastro">
          <q-table :data="dadosTableTrials" :columns="columnsTable" :loading="loading" :pagination.sync="pagination"
            :no-data-label="'Nenhum dado'" :no-results-label="'Nenhum resultado obtido'"
            :loading-label="'Carregando dados'" separator="cell" class="sticky-header-column-table">
            <template v-slot:bottom></template>
            <template v-slot:top>
              <div class="q-table__title">Trials</div>
            </template>
            <template v-slot:body="props">
              <q-tr :props="props">
                <q-td align="left" key="legenda" no-hover>
                  <strong>{{ props.row.legenda }}</strong>
                  <span class="text-grey-8"> {{ props.row.sublegenda }}</span>
                  <q-icon name="help_outline" class="float-right" color="grey-8" size="xs">
                    <q-tooltip>{{ props.row.descricao }}</q-tooltip>
                  </q-icon>
                </q-td>
                <q-td no-hover :align="props.row.align" v-for="i in columnsTable.length - 1" :key="`referencia${i}`">
                  <td-metricas-saas :celula="props.row" :index="i" />
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </div>
      </div>

      <!-- Menu lateral -->
      <q-page-sticky position="top-right" :offset="[0, 160]" style="z-index: 2">
        <q-btn v-show="!$q.fullscreen.isActive" :color="openOpcoesAvancadas ? 'red-7' : 'blue-7'"
          :icon="openOpcoesAvancadas ? 'close' : 'settings'" class="rotate-0"
          style="border-top-left-radius: 50px; border-bottom-left-radius: 50px; z-index: 2"
          @click="openOpcoesAvancadas = !openOpcoesAvancadas">
          <q-tooltip :content-class="openOpcoesAvancadas
            ? 'bg-negative text-white text-center'
            : 'bg-secondary text-white text-center'
            ">{{ openOpcoesAvancadas ? 'Fechar Opções Avançadas' : 'Opções Avançadas' }}</q-tooltip>
        </q-btn>
      </q-page-sticky>
      <q-drawer side="right" v-model="openOpcoesAvancadas" bordered :width="300" :breakpoint="500" behavior="default"
        content-class="bg-white">
        <q-scroll-area class="fit">
          <div class="q-pa-sm">
            <div class="q-table__title">Opções Avançadas</div>
            <div>
              <q-list class="rounded-borders" style="max-width: 350px; margin-left: -10px; width: 300px">
                <q-item-label header></q-item-label>

                <q-item clickable v-ripple @click.exact="openParametros()">
                  <q-item-section>
                    <q-item-label lines="1">Configurações de CAC</q-item-label>
                    <q-item-label caption>Custo de Aquisição do Cliente</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </div>
        </q-scroll-area>
      </q-drawer>
    </div>

    <modal-metricas-saas-opcoes />
    <modal-detalhar-metricas-saas />
  </q-page>
</template>

<script>
import axios from '@/axios-auth';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { bus } from '@/main';
import { exportFile } from 'quasar';
import { Notify } from 'quasar';
import AreaChart from '../components/Graficos/area-chart.vue';
import MixedChart from '../components/Graficos/mixed-chart.vue';
import LineChart from '../components/Graficos/line-chart.vue';

function wrapCsvValue(value) {
  let formatted = value === void 0 || value === null ? '' : String(value);
  formatted = formatted.split('"').join('""');
  formatted = formatted.replaceAll('ã', 'a').replaceAll('ç', 'c').replaceAll('é', 'e');
  return `"${formatted}"`;
}

export default {
  meta: {
    title: 'DSB | Métricas SaaS',
  },
  components: {
    AreaChart,
    MixedChart,
    LineChart
  },
  data() {
    return {
      // Filtros
      referencia: moment().format('MM/YYYY'),
      // Métricas
      loading: false,
      dados: {},
      semPermissao: false,
      pagination: {
        sortBy: 'mes',
        descending: true,
        rowsPerPage: 0,
        page: 1,
      },
      openOpcoesAvancadas: false,
      quantidadeMesesTabela: [3, 6, 9, 12].includes(
        parseInt(localStorage.getItem('metricas_saas__quantidade_meses_tabela'))
      )
        ? parseInt(localStorage.getItem('metricas_saas__quantidade_meses_tabela'))
        : 12,
      opcoesMesesTabela: [
        {
          label: '12 Meses',
          value: 12,
        },
        {
          label: '9 Meses',
          value: 9,
        },
        {
          label: '6 Meses',
          value: 6,
        },
        {
          label: '3 Meses',
          value: 3,
        },
      ],
      dadosCopy: {},
      // Gráficos
      graficoMrrOptions: {
        colors: ['#2E7D32'],
        legend: { position: 'top' },
        vAxis: { minValue: 0, format: 'R$ ' },
      },
      graficoLtvOptions: {
        colors: ['#4CAF50', '#2196F3'],
        legend: { position: 'top' },
        vAxes: {
          0: { minValue: 0, format: 'R$ ' },
          1: { minValue: 0, format: '# meses' },
        },
        series: {
          0: { type: 'bars', targetAxisIndex: 0 },
          1: { type: 'line', targetAxisIndex: 1 },
        },
      },
      graficoGrowthChurnOptions: {
        yaxis: [
          {
            labels: {
              formatter: this.formatCurrency,
            },
          },
          {
            show: false,
            labels: {
              formatter: this.formatCurrency,
            },
          },
          {
            opposite: true,
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
            },
          },
          {
            opposite: true,
            show: false,
            labels: {
              formatter: function (value) {
                return parseInt(value);
              },
            },
          },
        ],
      },
      localeFormat: {
        style: 'currency',
        currency: 'BRL',
      },
      tiposDetalhamento: {},
      opcoesRecorrencias: {},
      // Autocomplete
      carregandoCadastro: false,
      optionsCadastros: [],
      cadastro: null,
    };
  },
  methods: {
    formatCurrency(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      return formatter.format(value);
    },
    returnMonth() {
      return moment(this.referencia, 'MM/YYYY').format('MMM/YYYY');
    },
    changeMonth(value) {
      let referencia = moment(this.referencia, 'MM/YYYY');

      if (value === 'prev') {
        referencia.subtract(1, 'months');
      } else {
        referencia.add(1, 'months');
      }

      this.referencia = referencia.format('MM/YYYY');
    },
    loadMetricasSaas() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      const params = {
        referencia: this.referencia.replace(/\//g, '-'),
      };

      if (this.cadastro) {
        params.cadastro_id = this.cadastro.id;
      }

      axios
        .get('/metricas-saas', { params })
        .then(res => {
          this.loading = false;

          if (!res.data.success) {
            this.$q.notify({
              color: 'orange',
              message: res.data.message,
              position: 'top-right',
            });
            return;
          }

          this.dados = JSON.parse(JSON.stringify(res.data.data));
          this.dadosCopy = JSON.parse(JSON.stringify(res.data.data));
          this.referencia = moment(res.data.params.referencia, 'MM-YYYY').format('MM/YYYY');
          this.tiposDetalhamento = res.data.tipos_detalhamento;
          this.opcoesRecorrencias = res.data.opcoes_recorrencias;

          this.updateTable();
        })
        .catch(() => {
          this.loading = false;
          this.semPermissao = true;

          this.$q.notify({
            color: 'red',
            message: 'Houve um erro ao carregar as métricas ou você não possui permissão',
            position: 'top-right',
          });
        });
    },
    exportTable() {
      axios
        .post('/metricas-saas?exportar=true', {
          headers: {
            Accept: 'text/html,application/json',
          },
        })
        .then(res => {

          Notify.create({
            message: res.data.message,
            color: res.data.success ? 'green' : 'orange',
            position: 'top-right',
          });

          if (!res.data.success) return;
          window.open(res.data.linkArquivo, 'blank');
        })
        .catch(() => {
          Notify.create({
            message: 'Não foi possível contactar, ou você não possui permissão!',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    openParametros() {
      this.$store.dispatch('controlDialogOpcoesMetricasSaas', true);
    },
    getNomeMes(meses = 0) {
      const data = moment(this.referencia, 'MM/YYYY');

      if (meses > 0) {
        data.add(meses, 'months');
      } else if (meses < 0) {
        data.subtract(Math.abs(meses), 'months');
      }

      return data.format('MMMM');
    },
    updateTable() {
      const metricasSaas = JSON.parse(JSON.stringify(this.dadosCopy.metricas_saas));
      this.dados.metricas_saas = metricasSaas.slice(Math.abs(this.quantidadeMesesTabela) * -1);
    },
    // Autocomplete methods
    filterCadastro(val, update, abort) {
      update(() => {
        if (val === null) return abort();

        this.carregandoCadastro = true;

        axios.get('/cadastros?ativo=1&cliente=1&busca=' + val).then(res => {
          this.carregandoCadastro = false;
          this.optionsCadastros = res.data.data.cadastros;
        });
      });
    },
    formatDisplay(result) {
      return result.nome;
    },
    createValue(val, done) {
      this.$q.notify({
        message: 'Cadastro não foi encontrado.',
        color: 'orange',
        position: 'top-right',
      });
    },
    resultEmitRecebe(result) {
      this.cadastro = result;
      this.loadMetricasSaas();
    },
    displayMontar(value) {
      return !value.nome ? value.name : value.nome;
    },
    abreCadastro(value) {
      this.$store.dispatch('cadastro', {
        nome: value.name,
        noSearch: true,
      });
    },
    verifyCadastro() {
      const cadastroId = this.$route.query.cadastro_id;

      if (!cadastroId) {
        return;
      }

      this.cadastro = {
        id: cadastroId,
        nome: '',
      };

      axios
        .get(`/cadastros/view/${cadastroId}`)
        .then(res => {
          if (!res.data.success) {
            this.$q.notify({
              message: 'Não foi possível recuperar o cadastro',
              color: 'red',
              position: 'top-right',
            });
            return;
          }

          const { cadastro } = res.data.data;
          this.cadastro = cadastro;

          bus.$emit('updateAutocompleteField', {
            id: cadastro.id,
            name: cadastro.nome,
            label: cadastro.nome,
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'Não foi possível recuperar o cadastro',
            color: 'red',
            position: 'top-right',
          });
        });
    },
    // Fullscreen methods
    closeFullscreen() {
      this.$q.fullscreen.exit();
    },
    toogleFullscreen() {
      if (this.$q.fullscreen.isActive) {
        return this.closeFullscreen();
      } else {
        this.openOpcoesAvancadas = false;
      }

      this.$q.fullscreen.request();
    },
    keydownFullscreenEvent(event) {
      if (event.keyCode === 122 && !event.shiftKey && !event.altKey && !event.ctrlKey) {
        // F11
        event.preventDefault();
        this.toogleFullscreen();
      }
    },
    loadFullscreenChangeListener() {
      window.addEventListener('fullscreenchange', this.changeLayout);
      window.addEventListener('mozfullscreenchange', this.changeLayout);
      window.addEventListener('webkitfullscreenchange', this.changeLayout);
      window.addEventListener('msfullscreenchange', this.changeLayout);
      window.addEventListener('keydown', this.keydownFullscreenEvent);
    },
    removeFullscreenChangeListener() {
      window.removeEventListener('fullscreenchange', this.changeLayout);
      window.removeEventListener('mozfullscreenchange', this.changeLayout);
      window.removeEventListener('webkitfullscreenchange', this.changeLayout);
      window.removeEventListener('msfullscreenchange', this.changeLayout);
      window.removeEventListener('keydown', this.keydownFullscreenEvent);
    },
    changeLayout(fullscreenActive = null) {
      if (typeof fullscreenActive !== 'boolean') {
        fullscreenActive = this.$q.fullscreen.isActive;
      }

      let display = 'block';
      let paddingLeft = '57px';

      if (fullscreenActive) {
        display = 'none';
        paddingLeft = '0px';
      }

      document.getElementById('headerPrincipal').style.display = display;
      document.getElementById('drawerPrincipal').style.display = display;
      document.getElementById('pageContainerPrincipal').style.paddingLeft = paddingLeft;
    },
    openDadosTd(campo, referencia) {
      const tipoDetalhamento = this.tiposDetalhamento[campo];

      bus.$emit('abrirModalDetalharMetricasSaas', { campo, referencia, tipoDetalhamento });
    },
  },
  computed: {
    graficoArrData() {
      if (!this.dados.mrr_mes_mes) {
        return [];
      }

      const seriesData = [];

      this.dados.mrr_mes_mes.forEach(recorrencia => {
        const valor = parseFloat(recorrencia.valor);
        // const quantidade = parseInt(recorrencia.mrr_quantidade);
        // `${valor.toLocaleString('pt-BR', this.localeFormat)} \n (${quantidade} recorrências)`
        let mes = moment(recorrencia.referencia, 'MM/YYYY').format('MMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);

        seriesData.push({
          x: mes,
          y: valor,
        });
      });

      return [
        {
          name: 'Valor',
          data: seriesData,
        }
      ];
    },
    graficoCacData() {
      if (!this.dados.metricas_saas) {
        return [];
      }

      const seriesData = [];

      this.dados.metricas_saas.forEach(metricasSaas => {
        const valor = parseFloat(metricasSaas.cac);
        let mes = moment(metricasSaas.referencia, 'MM/YYYY').format('MMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);

        seriesData.push({
          x: mes,
          y: valor,
          label: valor.toLocaleString('pt-BR', this.localeFormat),
        });
      });

      return [
        {
          name: 'CAC',
          data: seriesData,
        }
      ];
    },
    graficoLifetimeValueData() {
      if (!this.dados.lifetime_value_graph) {
        return [];
      }

      const seriesLifetimeValue = [];
      const seriesLifetime = [];

      this.dados.lifetime_value_graph.forEach(lifetimeData => {
        const lifetimeValue = parseFloat(lifetimeData.lifetime_value);
        const lifetime = parseFloat(lifetimeData.lifetime);
        let mes = moment(lifetimeData.referencia, 'MM/YYYY').format('MMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);

        seriesLifetimeValue.push({
          x: mes,
          y: lifetimeValue || 0,
        });

        seriesLifetime.push({
          x: mes,
          y: lifetime || 0,
        });
      });

      return [
        {
          name: 'LifeTime Value',
          type: 'column',
          data: seriesLifetimeValue,
        },
        {
          name: 'Life Time',
          type: 'line',
          data: seriesLifetime,
        },
      ];
    },
    graficoPaybackData() {
      if (!this.dados.ticket_medio || !this.dados.cac) {
        return [];
      }

      const seriesValor = [];
      const seriesCac = [];
      const cac = parseFloat(this.dados.cac.mes_atual);
      const ticketMedio = this.dados.ticket_medio.mes_atual;

      let valor = cac * -1;
      let mesesUltrapassados = 0;
      let mesAtual = 1;

      seriesValor.push({ x: 0, y: 0 });
      seriesCac.push({ x: 0, y: cac });

      seriesValor.push({ x: mesAtual, y: valor });
      seriesCac.push({ x: mesAtual, y: cac });

      while (mesesUltrapassados < 5 && mesAtual <= 36) {
        mesAtual++;
        valor += ticketMedio;

        seriesValor.push({ x: mesAtual, y: valor });
        seriesCac.push({ x: mesAtual, y: cac });

        if (valor > cac) mesesUltrapassados++;
      }

      return [
        {
          name: 'Valor',
          type: 'line',
          data: seriesValor,
        },
        {
          name: 'CAC',
          type: 'line',
          data: seriesCac,
        },
      ];
    },
    graficoPaybackOptions() {
      const ticks = [];

      if (this.graficoPaybackData) {
        this.graficoPaybackData.slice(1).forEach((item, index) => {
          ticks.push(index);
        });
      }

      return {
        colors: ['#4CAF50', '#E53935'],
        curveType: 'function',
        legend: { position: 'top' },
        series: { 2: { type: 'line' } },
        vAxis: {
          format: 'R$ ',
        },
        hAxis: { ticks },
      };
    },

    graficoGrowthChurnData() {
      if (!this.dados.growth_churn_graph || this.dados.growth_churn_graph.length === 0) {
        return [];
      }

      const growthSeries = [];
      const churnSeries = [];
      const totalGrowthSeries = [];
      const totalChurnSeries = [];

      this.dados.growth_churn_graph.forEach(growthChurn => {
        const growthValor = parseFloat(growthChurn.growth_valor);
        const churnValor = parseFloat(growthChurn.churn_valor);

        const novoMrrQuantidade = parseInt(growthChurn.novo_mrr_quantidade);
        const expansaoMrrQuantidade = parseInt(growthChurn.expansao_mrr_quantidade);
        const totalGrowthQuantidade = novoMrrQuantidade + expansaoMrrQuantidade;

        const perdaMrrQuantidade = parseInt(growthChurn.perda_mrr_quantidade);
        const contracaoMrrQuantidade = parseInt(growthChurn.contracao_mrr_quantidade);
        const totalChurnQuantidade = perdaMrrQuantidade + contracaoMrrQuantidade;

        let mes = moment(growthChurn.referencia, 'MM/YYYY').format('MMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);

        growthSeries.push({
          x: mes,
          y: growthValor
        });
        churnSeries.push({
          x: mes,
          y: churnValor
        });
        totalGrowthSeries.push({
          x: mes,
          y: totalGrowthQuantidade
        });
        totalChurnSeries.push({
          x: mes,
          y: totalChurnQuantidade
        });
      });

      return [
        {
          name: 'Growth',
          data: growthSeries,
        },
        {
          name: 'Churn',
          data: churnSeries,
          color: "#ff4560"
        },
        {
          name: 'Growth Qtd',
          data: totalGrowthSeries,
          hidden: true,
          color: "#00e396"
        },
        {
          name: 'Churn Qtd',
          data: totalChurnSeries,
          hidden: true,
          color: "#feb019"
        },
      ];
    },

    graficoValorGrowthChurnData() {
      if (!this.dados.metricas_saas || this.dados.metricas_saas.length === 0) {
        return;
      }

      const data = [['Referência', 'Growth', 'Churn']];

      this.dados.metricas_saas.forEach(metrica => {
        const growth = parseFloat(metrica.growth_valor);
        const churn = parseFloat(metrica.churn_valor);
        let mes = moment(metrica.referencia, 'MM/YYYY').format('MMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);

        data.push([mes, growth, churn]);
      });

      return data;
    },
    columnsTable() {
      if (!this.dados.metricas_saas) {
        return [];
      }

      const columns = this.dados.metricas_saas.map((metrica, index) => {
        let mes = moment(metrica.referencia, 'MM/YYYY').format('MMM/YY');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);

        return {
          name: `referencia${index}`,
          label: mes,
          align: 'center',
          sortable: false,
        };
      });

      columns.unshift({
        name: `legenda`,
        label: '',
        align: 'center',
        sortable: false,
      });

      return columns;
    },
    dadosTable() {
      if (!this.dados.metricas_saas) {
        return [];
      }

      const dados = [
        {
          legenda: 'MRR Inicial',
          sublegenda: '(a)',
          type: '$',
          align: 'right',
          campo: 'mrr',
          descricao:
            'O MRR é a receita recorrente mensal que seu negócio de assinaturas possui no final do mês anterior',
          clickable: false,
        },
        {
          legenda: 'Novo MRR',
          type: '$',
          align: 'right',
          campo: 'novo_mrr',
          descricao: 'O valor total das novas recorrências adicionadas no mês',
          clickable: true,
        },
        {
          legenda: 'Expansão MRR',
          type: '$',
          align: 'right',
          campo: 'expansao_mrr',
          descricao:
            'Todas as expansões dos valores das assinaturas deste mês em relação ao anterior',
          clickable: true,
        },
        {
          legenda: 'Growth',
          sublegenda: '(b)',
          type: '$',
          align: 'right',
          campo: 'growth_valor',
          descricao:
            'A porcentagem do valor das novas assinaturas mais o valor de expansão em relação ao MRR Inicial',
          clickable: false,
          subitem: {
            type: '%',
            align: 'right',
            campo: 'growth',
            isChild: true,
          },
        },
        {
          legenda: 'Perda de MRR',
          type: '$',
          align: 'right',
          campo: 'perda_mrr',
          descricao:
            'O valor total das recorrências que foram canceladas ou usuários que sairam do sistema',
          clickable: true,
          prefixo: '-',
        },
        {
          legenda: 'Contração de MRR',
          type: '$',
          align: 'right',
          campo: 'contracao_mrr',
          descricao:
            'Todas as reduções dos valores das assinaturas deste mês em relação ao anterior',
          clickable: true,
          prefixo: '-',
        },
        {
          legenda: 'Churn',
          sublegenda: '(c)',
          type: '$',
          align: 'right',
          campo: 'churn_valor',
          descricao:
            'A porcentagem do valor da perda de MRR mais o valor de contração em relação ao MRR Inicial',
          clickable: false,
          prefixo: '-',
          subitem: {
            type: '%',
            align: 'right',
            campo: 'churn',
            isChild: true,
          },
        },
        {
          legenda: 'Congelado',
          type: '$',
          align: 'right',
          campo: 'congelado',
          descricao: 'O valor total das recorrências ativas que estão congeladas no sistema',
          clickable: true,
        },
        {
          legenda: 'Net MRR',
          sublegenda: '(b - c)',
          type: '$',
          align: 'right',
          campo: 'net_mrr_valor',
          descricao: 'Percentual de crescimento de MRR em relação ao MRR Inicial (Growth - Churn)',
          clickable: false,
          subitem: {
            type: '%',
            align: 'right',
            campo: 'net_mrr',
            isChild: true,
          },
        },
        {
          legenda: 'Net MRR Churn',
          type: '$',
          align: 'right',
          campo: 'net_mrr_churn_valor',
          descricao: 'Percentual da perda de MRR em relação ao MRR Inicial (Expansão - Churn)',
          clickable: false,
          subitem: {
            type: '%',
            align: 'right',
            campo: 'net_mrr_churn',
            isChild: true,
          },
        },
        {
          legenda: 'Net Revenue Retention',
          type: '%',
          align: 'right',
          campo: 'net_revenue_retention',
          descricao: 'Percentual de retenção da receita sem novas vendas',
          clickable: false,
        },
        {
          legenda: 'Quantidade Clientes',
          descricao: 'Quantidade de clientes ativos.',
          align: 'right',
          campo: 'quantidade_clientes',
          clickable: false,
        },
        {
          legenda: 'Logo Churn',
          sublegenda: '',
          type: '',
          align: 'right',
          campo: 'churn_quantidade_clientes',
          descricao: 'Percentual de Churn de Clientes',
          clickable: false,
          subitem: {
            type: '%',
            align: 'right',
            campo: 'logo_churn',
            isChild: true,
          },
        },
        {
          legenda: 'Quantidade MRR',
          descricao: 'Quantidade de assinaturas/recorrências ativas',
          align: 'right',
          campo: 'mrr_quantidade',
          clickable: false,
        },
        {
          legenda: 'Ticket Médio',
          type: '$',
          align: 'right',
          campo: 'ticket_medio',
          descricao: 'O valor médio das recorrências (MRR / Quantidade de clientes ativos)',
          clickable: false,
        },
        {
          legenda: 'Ticket Médio MRR',
          type: '$',
          align: 'right',
          campo: 'ticket_medio_mrr',
          descricao: 'O valor médio das recorrências (MRR / Quantidade de recorrências ativas)',
          clickable: false,
        },
        {
          legenda: 'Quick Ratio',
          sublegenda: '(b / c)',
          type: 'currency',
          align: 'right',
          campo: 'quick_ratio',
          descricao: 'Taxa de crescimento sobre o churn (growth / churn)',
          clickable: false,
        },
        {
          legenda: 'Lifetime Value',
          type: '$',
          align: 'right',
          campo: 'lifetime_value',
          descricao: 'Projeção futura do tempo de vida da empresa baseado no churn',
          clickable: false,
          subitem: {
            type: '',
            align: 'right',
            campo: 'lifetime',
            sufixo: ' meses',
            isChild: true,
          },
        },
        {
          legenda: 'Lifetime baseado nos clientes',
          type: '',
          align: 'right',
          campo: 'lifetime_clientes',
          descricao: 'Tempo médio em meses de vida dos clientes',
          clickable: false,
          sufixo: ' meses',
        },
        {
          legenda: 'Lifetime baseado nas recorrências',
          type: '',
          align: 'right',
          campo: 'lifetime_mrr',
          descricao: 'Tempo médio em meses de vida dos clientes',
          clickable: false,
          sufixo: ' meses',
        },
        {
          legenda: 'MRR Final',
          sublegenda: '(a + b - c)',
          type: '$',
          align: 'right',
          campo: 'mrr_final',
          descricao: 'O valor final do MRR (MRR Inicial + Valor do Growth - Valor do Churn)',
          clickable: false,
        },
        {
          legenda: 'ARR',
          type: '$',
          align: 'right',
          campo: 'arr',
          descricao: 'A receita total das recorrências anualizadas (MRR Final X 12)',
          clickable: false,
        },
        {
          legenda: 'CAC',
          type: '$',
          align: 'right',
          campo: 'cac',
          descricao: 'O custo para conquistar um novo cliente',
          clickable: true,
        },
        {
          legenda: 'LTV/CAC',
          type: 'currency',
          align: 'right',
          campo: 'indice_ltv_cac',
          descricao: 'O lifetime value sobre o CAC',
          clickable: false,
        },
      ];

      dados.forEach(dado => {
        this.dados.metricas_saas.forEach((metrica, index) => {
          dado[`referencia${index + 1}`] = metrica[dado.campo];
          dado[`referencia${index + 1}_text`] = metrica.referencia;
        });
        if (dado.subitem) {
          this.dados.metricas_saas.forEach((metrica, index) => {
            dado.subitem[`referencia${index + 1}`] = metrica[dado.subitem.campo];
          });
        }
      });

      return dados;
    },
    dadosTableTrials() {
      if (!this.dados.metricas_saas) {
        return [];
      }

      const dados = [
        {
          legenda: 'Trials',
          type: '$',
          align: 'right',
          campo: 'trials',
          descricao: 'As recorrências que estão em período de teste',
          subitem: {
            type: '',
            align: 'right',
            campo: 'trials_quantidade',
            isChild: true,
          },
        },
        {
          legenda: 'Trials ganhos',
          type: '$',
          align: 'right',
          campo: 'trials_convertidos',
          descricao: 'As recorrências que após o período de teste foram convertidas',
          subitem: {
            type: '',
            align: 'right',
            campo: 'trials_convertidos_quantidade',
            isChild: true,
          },
        },
        {
          legenda: 'Trials perdidos',
          type: '$',
          align: 'right',
          campo: 'trials_perdidos',
          descricao: 'As recorrências que após o período de teste foram perdidas',
          subitem: {
            type: '',
            align: 'right',
            campo: 'trials_perdidos_quantidade',
            isChild: true,
          },
        },
      ];

      dados.forEach(dado => {
        this.dados.metricas_saas.forEach((metrica, index) => {
          dado[`referencia${index + 1}`] = metrica[dado.campo];
        });
        if (dado.subitem) {
          this.dados.metricas_saas.forEach((metrica, index) => {
            dado.subitem[`referencia${index + 1}`] = metrica[dado.subitem.campo];
          });
        }
      });

      return dados;
    },
    graficoHistoricoClientesData() {
      if (!this.dados.historico_clientes) {
        return [];
      }

      const seriesClientesAtivos = [];
      const seriesClientesGrowth = [];
      const seriesClientesChurn = [];

      this.dados.historico_clientes.forEach(historicoCliente => {
        let mes = moment(historicoCliente.referencia, 'MM/YYYY').format('MMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);

        seriesClientesAtivos.push({
          x: mes,
          y: parseInt(historicoCliente.clientes_ativos) || 0,
        });

        seriesClientesGrowth.push({
          x: mes,
          y: parseInt(historicoCliente.clientes_in) || 0,
        });

        seriesClientesChurn.push({
          x: mes,
          y: parseInt(historicoCliente.clientes_out) || 0,
        });
      });

      return [
        {
          name: 'Clientes Ativos',
          type: 'column',
          data: seriesClientesAtivos,
        },
        {
          name: 'Clientes Growth',
          type: 'line',
          data: seriesClientesGrowth,
        },
        {
          name: 'Clientes Churn',
          type: 'line',
          data: seriesClientesChurn,
          color: "#ff4560"
        },
      ];
    }
  },
  beforeMount() {
    bus.$off('loadMetricasSaas');
  },
  mounted() {
    this.verifyCadastro();
    this.loadMetricasSaas();
    this.loadFullscreenChangeListener();

    bus.$on('loadMetricasSaas', () => {
      this.loadMetricasSaas();
    });
  },
  beforeDestroy() {
    this.changeLayout(false);
    this.removeFullscreenChangeListener();
  },
  watch: {
    referencia() {
      this.loadMetricasSaas();
    },
    cadastro() {
      let newUrl = '/metricas-saas';

      if (this.cadastro) {
        newUrl += `?cadastro_id=${this.cadastro.id}`;
      }

      window.history.replaceState(this.cadastro, '', newUrl);
    },
    quantidadeMesesTabela() {
      if (!this.dados.metricas_saas) return;

      this.updateTable();
      localStorage.setItem('metricas_saas__quantidade_meses_tabela', this.quantidadeMesesTabela);
    },
  },
};
</script>

<style lang="stylus" scoped>

div[class^="col-"] .q-card {
  height: 100%;
}

table.q-table {
  overflow: hidden;
  z-index: 1;
}

table.q-table tbody tr:hover td::before {
  content: none;
  background: none;
}

table.q-table td:hover:not(:first-child)::before {
  content: '' !important;

  width: calc(5000px + 100%);
  height: 100%;

  position: absolute;
  left: -5000px;
  top: 0;

  background-color: rgba(66, 133, 244, .15) !important;
}

table.q-table td:hover:not(:first-child)::after {
  content: '';

  width: 100%;
  height: calc(5000px + 100%);

  position: absolute;
  top: -5000px;
  left: 0;

  background-color: rgba(66, 133, 244, .15);
}

.note-card {
  height: inherit !important;
  background-color: #fff9c4;

  border-left: 3px solid #fdd835;
  border-right: 3px solid #fdd835;
}
</style>

<style lang="stylus">
@media (max-width: 1439px) {
  .sticky-header-column-table {
    /* height or max-height is important */
    max-height: calc(95vh - 64px);

    tr th {
      position: sticky;
      /* higher than z-index for td below */
      z-index: 2;
      /* bg color is important; just specify one */
      background: #fff;
    }

    /* this will be the loading indicator */
    thead tr:last-child th {
      /* height of all previous header rows */
      top: 48px;
      /* highest z-index */
      z-index: 3;
    }

    thead tr:first-child th {
      top: 0;
      z-index: 1;
    }

    tr:first-child th:first-child {
      /* highest z-index */
      z-index: 3;
    }

    td:first-child, th:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background: #fff;
    }
  }
}
</style>
